import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "getUser";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getUser: null,
  };
}

export const getUser = createAsyncThunk(
  "getUser",
  async ({ userId }, rejectWithValue) => {
    try {
      const response = await api.get(`/super/api/user/detail/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);



const getUserSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetgetUser: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getUser = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getUser = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetgetUser } = getUserSlice.actions;
export const getUserReducer = getUserSlice.reducer;
