import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "getApplicationInfos";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getApplicationInfos: null,
  };
}

export const getApplicationInfos = createAsyncThunk(
  "getApplicationInfos",
  async (rejectWithValue) => {
    try {
      const response = await api.get("/super/api/application-info/get-all");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);



const getApplicationInfosSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetGetApplicationInfos: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getApplicationInfos = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationInfos.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getApplicationInfos.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getApplicationInfos = action.payload;
      })
      .addCase(getApplicationInfos.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetGetApplicationInfos } = getApplicationInfosSlice.actions;
export const getApplicationInfosReducer = getApplicationInfosSlice.reducer;
