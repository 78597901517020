import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'deleteExam';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        deleteExam: null,
    };
}

export const deleteExam = createAsyncThunk('deleteExam', async (id, { rejectWithValue }) => {
    try {
        const response = await api.delete(`/super/api/target-exam/delete/${id}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const deleteExamSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetDeleteExam: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.deleteExam = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(deleteExam.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(deleteExam.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.deleteExam = action.payload;
            })
            .addCase(deleteExam.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetDeleteExam } = deleteExamSlice.actions;
export const deleteExamReducer = deleteExamSlice.reducer;
