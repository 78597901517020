import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "getUsers";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getUsers: null,
  };
}

export const getUsers = createAsyncThunk(
  "getUsers",
    async ({ pageSize, pageNumber, search }, rejectWithValue) => {
    try {
      const response = await api.get(`/super/api/user/get-all?pageSize=${pageSize}&pageNumber=${pageNumber}&search=${search}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);



const getUsersSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetGetUsers: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getUsers = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getUsers = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetGetUsers } = getUsersSlice.actions;
export const getUsersReducer = getUsersSlice.reducer;
