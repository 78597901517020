import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'addLesson';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        addLesson: null,
    };
}

    
export const addLesson = createAsyncThunk('addLesson', async ({ name, targetExamID, description, style, sortOrder }, { rejectWithValue }) => {
    try {
        const response = await api.post('/super/api/lesson/create', { name, targetExamId: targetExamID, description, style, sortOrder });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const addLessonSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetAddLesson: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.addLesson = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(addLesson.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(addLesson.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.addLesson = action.payload;
            })
            .addCase(addLesson.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetAddLesson } = addLessonSlice.actions;
export const addLessonReducer = addLessonSlice.reducer;