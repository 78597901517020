import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'updateLesson';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        updateLesson: null,
    };
}

    
export const updateLesson = createAsyncThunk('updateLesson', async ({ id, name, targetExamId, description, style, sortOrder }, { rejectWithValue }) => {
    try {
        const response = await api.put('/super/api/lesson/update', { id, name, targetExamId, description, style, sortOrder });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const updateLessonSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetUpdateLesson: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.updateLesson = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(updateLesson.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(updateLesson.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.addExam = action.payload;
            })
            .addCase(updateLesson.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetUpdateLesson } = updateLessonSlice.actions;
export const updateLessonReducer = updateLessonSlice.reducer;