import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "addPackage";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    addPackage: null,
  };
}

export const addPackage = createAsyncThunk(
  "addPackage",
  async (
    {
      name,
      description,
      price,
      style,
      totalRights,
      scope,
      validityPeriod,
      validityDuration,
      isDefault,
      sortOrder,
      isHidden,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/super/api/package/add", {
        name,
        description,
        price,
        style,
        totalRights,
        scope,
        validityPeriod,
        validityDuration,
        isDefault,
        sortOrder,
        isHidden,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addPackageSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetAddPackage: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.addPackage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPackage.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(addPackage.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.addPackage = action.payload;
      })
      .addCase(addPackage.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetAddPackage } = addPackageSlice.actions;
export const addPackageReducer = addPackageSlice.reducer;
