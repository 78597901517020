import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'createFeedbackType';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        createFeedbackType: null,
    };
}

    
export const createFeedbackType = createAsyncThunk('createFeedbackType', async ({ name }, { rejectWithValue }) => {
    try {
        const response = await api.post('/super/api/feedback/type/create', { name });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const createFeedbackTypeSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetcreateFeedbackType: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.createFeedbackType = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(createFeedbackType.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(createFeedbackType.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.createFeedbackType = action.payload;
            })
            .addCase(createFeedbackType.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetcreateFeedbackType } = createFeedbackTypeSlice.actions;
export const createFeedbackTypeReducer = createFeedbackTypeSlice.reducer;