import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { verifyCode } from "../../store/auth/verifyCode.slice";
import {
    Container,
    Content,
    Form,
    Button,
    Panel,
    Stack,
    VStack,
  } from "rsuite";

function VerifyCode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const verifyCodeState = useSelector((state) => state.verifyCode);

  const [code, setCode] = useState("");

  useEffect(() => {
    if (verifyCodeState.done) {
      navigate("/");
    }
    if (verifyCodeState.error !== false) {
      Swal.fire({
        title: "Hata!",
        text: verifyCodeState?.error?.errorMessage,
        icon: "error",
        confirmButtonText: "Tamam",
      });
    }
  }, [
    verifyCodeState,
    navigate,
    verifyCodeState?.error?.response?.data?.errorMessage,
  ]);

  function handleSubmit() {
    const phoneNumber = localStorage.getItem("phoneNumber");
    dispatch(verifyCode({ phoneNumber, verifyCode: code }));
  }

  return (
    <div className="full-page-center">
      <Container height={800}>
        <Content>
          <Stack
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
          >
            <Panel header="Flatun Admin" bordered style={{ width: 400 }}>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel> <i class="fa-solid fa-key"></i> Verify Code</Form.ControlLabel>
                  <Form.Control
                    onChange={(value) => setCode(value)}
                    name="name"
                  />
                </Form.Group>
                <VStack spacing={10}> 
                  <Button onClick={handleSubmit} appearance="primary" block>
                    Verify
                  </Button>
                </VStack>
              </Form>
            </Panel>
          </Stack>
        </Content>
      </Container>
    </div>
  );
}

export default VerifyCode;
