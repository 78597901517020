import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEntityMappings,
  createEntityMapping,
  updateEntityMapping,
  deleteEntityMapping,
  getEntityMappingDetail,
  resetState,
} from "../../store/entity/entity.slice";
import {
  Table,
  IconButton,
  Container,
  Header,
  Content,
  Button,
  Modal,
  Form,
  Input,
  Notification,
  toaster,
  Message,
} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";

const { Column, HeaderCell, Cell } = Table;

function Entities() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.entity.list);
  const create = useSelector((state) => state.entity.create);
  const update = useSelector((state) => state.entity.update);
  const detail = useSelector((state) => state.entity.detail);
  const deleteState = useSelector((state) => state.entity.delete);

  const [entityModal, setEntityModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentEntity, setCurrentEntity] = useState({
    id: "",
    name: "",
    description: "",
    entityType: "",
    entityId: "",
    localId: "",
    externalSystem: "",
    externalId: "",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState(null);
  const [loadingEntityId, setLoadingEntityId] = useState(null);

  useEffect(() => {
    dispatch(getEntityMappings());
  }, [dispatch]);

  useEffect(() => {
    if (detail.done || detail.error) {
      setLoadingEntityId(null);
      if (detail.done && detail.data) {
        setCurrentEntity(detail.data.result.data);
        setEntityModal(true);
      }
    }
  }, [detail.done, detail.error, detail.data]);

  useEffect(() => {
    if (create.done || update.done) {
      toaster.push(
        <Notification type="success" header="Success">
          Entity mapping {currentEntity.id ? "updated" : "created"} successfully
        </Notification>,
        { placement: "topEnd" }
      );
      handleCloseModal();
      dispatch(getEntityMappings());
      dispatch(resetState({ type: "create" }));
      dispatch(resetState({ type: "update" }));
    } else if (create.error || update.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to {currentEntity.id ? "update" : "create"} entity mapping
        </Notification>,
        { placement: "topEnd" }
      );
    }
  }, [create, update, currentEntity.id, dispatch]);

  useEffect(() => {
    if (deleteState.done) {
      toaster.push(
        <Notification type="success" header="Success">
          Entity mapping deleted successfully
        </Notification>,
        { placement: "topEnd" }
      );
      dispatch(getEntityMappings());
      dispatch(resetState({ type: "delete" }));
      setDeleteModalOpen(false);
    } else if (deleteState.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to delete entity mapping
        </Notification>,
        { placement: "topEnd" }
      );
    }
  }, [deleteState, dispatch]);

  const handleAddEntity = () => {
    setEditMode(true);
    setCurrentEntity({
      id: "",
      name: "",
      description: "",
      entityType: "",
      entityId: "",
      localId: "",
      externalSystem: "",
      externalId: "",
    });
    setEntityModal(true);
  };

  const handleCloseModal = () => {
    setEntityModal(false);
    setEditMode(false);
    setCurrentEntity({
      id: "",
      name: "",
      description: "",
      entityType: "",
      entityId: "",
      localId: "",
      externalSystem: "",
      externalId: "",
    });
  };

  const handleInputChange = (value, name) => {
    setCurrentEntity((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitEntity = () => {
    if (currentEntity.id) {
      dispatch(updateEntityMapping(currentEntity));
    } else {
      dispatch(createEntityMapping(currentEntity));
    }
  };

  const handleDeleteEntity = (entityId) => {
    setEntityToDelete(entityId);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (entityToDelete) {
      dispatch(deleteEntityMapping(entityToDelete));
      setEntityToDelete(null);
    }
  };

  return (
    <Container>
      <Header>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <h4>Entity Mappings</h4>
          <IconButton
            icon={<PlusIcon />}
            appearance="primary"
            onClick={handleAddEntity}
          >
            Add Entity Mapping
          </IconButton>
        </div>
      </Header>
      <Message
        type="success"
        style={{ marginBottom: 20 }}
        showIcon
        header="Entity mappings are used to map local entities to external systems."
      >
        <p>Name is the name of the entity mapping.</p>
        <p>Description is the description of the entity mapping.</p>
        <p>
          Entity Type is the type of the entity mapping. (e.g. Lesson, Exam,
          etc.)
        </p>
        <p>Entity ID is the ID of the entity mapping in the local system.</p>
        <p>Local ID is the ID of the entity mapping in the local system.</p>
        <p>
        For now, only the AI option is used.
        </p>
        <p>External ID is the ID of the entity mapping in the external system.</p>
      </Message>
      <Content>
        <Table
          height={window.innerHeight - 200}
          data={list.data?.result?.data || []}
          loading={list.spinner}
          wordWrap="break-word"
          rowHeight={60}
        >
          <Column width={200} flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column width={200} flexGrow={1}>
            <HeaderCell>Description</HeaderCell>
            <Cell dataKey="description" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Entity Type</HeaderCell>
            <Cell dataKey="entityType" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>External System</HeaderCell>
            <Cell dataKey="exteralSystem" />
          </Column>
          <Column width={100} fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <span style={{ display: "flex", gap: "5px" }}>
                  <IconButton
                    icon={<EditIcon />}
                    size="sm"
                    appearance="primary"
                    onClick={() => {
                      setEditMode(true);
                      setCurrentEntity(rowData);
                      setEntityModal(true);
                    }}
                  />
                  <IconButton
                    icon={<TrashIcon />}
                    size="sm"
                    color="red"
                    onClick={() => handleDeleteEntity(rowData.id)}
                  />
                </span>
              )}
            </Cell>
          </Column>
        </Table>
      </Content>

      <Modal open={entityModal} onClose={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title>
            {editMode ? (currentEntity.id ? "Edit" : "Create") : "View"} Entity
            Mapping
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control
                name="name"
                value={currentEntity.name}
                onChange={(value) => handleInputChange(value, "name")}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Form.Control
                name="description"
                value={currentEntity.description}
                onChange={(value) => handleInputChange(value, "description")}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Entity Type</Form.ControlLabel>
              <Form.Control
                name="entityType"
                value={currentEntity.entityType}
                onChange={(value) => handleInputChange(value, "entityType")}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Entity ID</Form.ControlLabel>
              <Form.Control
                name="entityId"
                value={currentEntity.entityId}
                onChange={(value) => handleInputChange(value, "entityId")}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Local ID</Form.ControlLabel>
              <Form.Control
                name="localId"
                value={currentEntity.localId}
                onChange={(value) => handleInputChange(value, "localId")}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>External System</Form.ControlLabel>
              <Form.Control
                name="externalSystem"
                value={currentEntity.exteralSystem}
                onChange={(value) => handleInputChange(value, "externalSystem")}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>External ID</Form.ControlLabel>
              <Form.Control
                name="externalId"
                value={currentEntity.externalId}
                onChange={(value) => handleInputChange(value, "externalId")}
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={handleSubmitEntity}
            appearance="primary"
            loading={create.spinner || update.spinner}
          >
            {currentEntity.id ? "Update" : "Create"}
          </Button>
          <Button onClick={handleCloseModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this entity mapping? This action
          cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={confirmDelete}
            appearance="primary"
            color="red"
            loading={deleteState.spinner}
          >
            Delete
          </Button>
          <Button onClick={() => setDeleteModalOpen(false)} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Entities;
