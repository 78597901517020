import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, resetGetUsers } from '../../store/user/getUsers.slice';
import { Table, Input, Pagination, Container, Header, Content, InputGroup, IconButton, Button, Stack } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import VisibleIcon from '@rsuite/icons/Visible';

const { Column, HeaderCell, Cell } = Table;

function Users() {
  const dispatch = useDispatch();
  const getUsersState = useSelector((state) => state.getUsers);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchUsers();
  }, [page, pageSize]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const fetchUsers = () => {
    dispatch(getUsers({ pageSize, pageNumber: page, search: searchTerm }));
  };

  const handleSearch = () => {
    setPage(1);
    fetchUsers();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleViewUserPackage = (userId) => {
    // Implement view user package functionality
    console.log('View user package for user ID:', userId);
  };

  const handleViewUserFeedbacks = (userId) => {
    // Implement view user feedbacks functionality
    console.log('View user feedbacks for user ID:', userId);
  };

  return (
    <Container>
      <Header>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          wordWrap: "break-word"
        }}>
          <h4>Users</h4>
          <InputGroup inside style={{ width: 300 }}>
            <Input 
              placeholder="Search users"
              value={searchTerm}
              onChange={setSearchTerm}
              onKeyPress={handleKeyPress}
            />
            <InputGroup.Button onClick={handleSearch}>
              <SearchIcon />
            </InputGroup.Button>
          </InputGroup>
        </div>
      </Header>
      <Content>
        <Table
          height={400}
          data={getUsersState.getUsers?.result?.users || []}
          loading={getUsersState.spinner}
          autoHeight
          wordWrap="break-word"
          rowHeight={60}
        >
          <Column width={150} flexGrow={1}>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Surname</HeaderCell>
            <Cell dataKey="surname" />
          </Column>
          <Column width={200} flexGrow={2}>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey="email" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Phone Number</HeaderCell>
            <Cell dataKey="phoneNumber" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Education Level</HeaderCell>
            <Cell dataKey="levelOfEducation.name" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Target Exam</HeaderCell>
            <Cell dataKey="targetExam.name" />
          </Column>
          <Column width={200} fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {rowData => (
                <Stack direction="column" spacing={4} alignItems="stretch">
                  <Button size="xs" onClick={() => handleViewUserPackage(rowData.id)}>
                    <VisibleIcon /> View Package
                  </Button>
                  <Button size="xs" onClick={() => handleViewUserFeedbacks(rowData.id)}>
                    <VisibleIcon /> View Feedbacks
                  </Button>
                </Stack>
              )}
            </Cell>
          </Column>
        </Table>

        <div style={{ padding: '20px 0', display: 'flex', justifyContent: 'end' }}>
          <Pagination
            total={getUsersState.getUsers?.result?.totalCount || 0}
            limit={pageSize}
            activePage={page}
            onChangePage={setPage}
          />
        </div>
      </Content>
    </Container>
  );
}

export default Users;
