import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationTemplates,
  createNotificationTemplate,
  updateNotificationTemplate,
  deleteNotificationTemplate,
  getNotificationTemplateDetail,
  resetState,
} from "../../store/notification/template.slice";
import {
  Table,
  IconButton,
  Container,
  Header,
  Content,
  Button,
  Modal,
  Form,
  Input,
  Notification,
  toaster,
} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";

const { Column, HeaderCell, Cell } = Table;

function NotificationTemplates() {
  const dispatch = useDispatch();
  const list = useSelector(state => state.notificationTemplate.list);
  const create = useSelector(state => state.notificationTemplate.create);
  const update = useSelector(state => state.notificationTemplate.update);
  const detail = useSelector(state => state.notificationTemplate.detail);
  const deleteState = useSelector(state => state.notificationTemplate.delete);


  const [templateModal, setTemplateModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState({
    id: "",
    name: "",
    title: "",
    body: "",
    imageURL: "",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const [loadingTemplateId, setLoadingTemplateId] = useState(null);

  useEffect(() => {
    setDeleteModalOpen(false);
    setTemplateModal(false);
    setEditMode(false);
    setCurrentTemplate({
      id: "",
      name: "",
      title: "",
      body: "",
      imageURL: "",
    });
  }, []);

  useEffect(() => {
    dispatch(getNotificationTemplates());
  }, [dispatch]);

  console.log(list.spinner);

  const fetchTemplates = useCallback(() => {
    dispatch(getNotificationTemplates());
  }, [dispatch]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  useEffect(() => {
    if (detail.done || detail.error) {
      setLoadingTemplateId(null);
      if (detail.done && detail.data) {
        setCurrentTemplate(detail.data.result.notificationTemplate);
        setTemplateModal(true);
      }
    }
  }, [detail.done, detail.error, detail.data]);

  useEffect(() => {
    if (create.done || update.done) {
      toaster.push(
        <Notification type="success" header="Success">
          Template {currentTemplate.id ? 'updated' : 'added'} successfully
        </Notification>,
        { placement: 'topEnd' }
      );
      handleCloseModal();
      dispatch(getNotificationTemplates());
      dispatch(resetState({ type: 'create' }));
      dispatch(resetState({ type: 'update' }));
    } else if (create.error || update.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to {currentTemplate.id ? 'update' : 'add'} template: {create.error || update.error}
        </Notification>,
        { placement: 'topEnd' }
      );
    }
  }, [create, update, currentTemplate.id, dispatch]);

  useEffect(() => {
    if (deleteState.done) {
      toaster.push(
        <Notification type="success" header="Success">
          Template deleted successfully
        </Notification>,
        { placement: 'topEnd' }
      );
      setDeleteModalOpen(false);
      dispatch(getNotificationTemplates());
      dispatch(resetState({ type: 'delete' }));
    } else if (deleteState.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to delete template: {deleteState.error}
        </Notification>,
        { placement: 'topEnd' }
      );
      setDeleteModalOpen(false);
    }
  }, [deleteState, dispatch]);

  const handleAddTemplate = () => {
    setEditMode(true);
    setCurrentTemplate({
      id: "",
      name: "",
      title: "",
      body: "",
      imageURL: "",
    });
    setTemplateModal(true);
  };

  const handleCloseModal = () => {
    setTemplateModal(false);
    setEditMode(false);
    setCurrentTemplate({
      id: "",
      name: "",
      title: "",
      body: "",
      imageURL: "",
    });
  };

  const handleInputChange = (value, name) => {
    setCurrentTemplate(prev => ({ ...prev, [name]: value }));
  };

  const handleViewTemplate = (templateId) => {
    setEditMode(false);
    setLoadingTemplateId(templateId);
    dispatch(resetState({ type: 'detail' })); // Detail state'ini sıfırla
    dispatch(getNotificationTemplateDetail(templateId));
  };

  const handleEditTemplate = () => {
    setEditMode(true);
  };

  const handleSubmitTemplate = () => {
    if (currentTemplate.id) {
      dispatch(updateNotificationTemplate(currentTemplate));
    } else {
      dispatch(createNotificationTemplate(currentTemplate));
    }
  };

  const handleDeleteTemplate = (templateId) => {
    setTemplateToDelete(templateId);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (templateToDelete) {
      dispatch(deleteNotificationTemplate(templateToDelete));
      setTemplateToDelete(null);
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setTemplateToDelete(null);
  };

  return (
    <Container>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
          <h4>Notification Templates</h4>
          <IconButton icon={<PlusIcon />} appearance="primary" onClick={handleAddTemplate}>
            Add Template
          </IconButton>
        </div>
      </Header>

      <Content>
        <Table
          height={window.innerHeight - 200}
          data={list.data?.result?.notifications || []}
          loading={list.spinner}
          wordWrap="break-word"
          rowHeight={60}
        >
          <Column width={200} flexGrow={1}>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column width={200} flexGrow={1}>
            <HeaderCell>Title</HeaderCell>
            <Cell dataKey="title" />
          </Column>
          <Column width={300} flexGrow={2}>
            <HeaderCell>Body</HeaderCell>
            <Cell dataKey="body" />
          </Column>
          <Column width={100} fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <IconButton
                    icon={<EditIcon />}
                    size="sm"
                    appearance="primary"
                    loading={loadingTemplateId === rowData.id}
                    onClick={() => handleViewTemplate(rowData.id)}
                  />
                  <IconButton
                    icon={<TrashIcon />}
                    size="sm"
                    color="red"
                    onClick={() => handleDeleteTemplate(rowData.id)}
                  />
                </span>
              )}
            </Cell>
          </Column>
        </Table>
      </Content>

      <Modal open={templateModal} onClose={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title>
            {editMode ? "Edit Template" : "View Template"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control
                name="name"
                value={currentTemplate.name || ""}
                onChange={(value) => handleInputChange(value, "name")}
                disabled={!editMode}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Title</Form.ControlLabel>
              <Form.Control
                name="title"
                value={currentTemplate.title || ""}
                onChange={(value) => handleInputChange(value, "title")}
                disabled={!editMode}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Body</Form.ControlLabel>
              <Input
                as="textarea"
                rows={3}
                name="body"
                value={currentTemplate.body || ""}
                onChange={(value) => handleInputChange(value, "body")}
                disabled={!editMode}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Image URL</Form.ControlLabel>
              <Form.Control
                name="imageURL"
                value={currentTemplate.imageURL || ""}
                onChange={(value) => handleInputChange(value, "imageURL")}
                disabled={!editMode}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {editMode ? (
            <Button
              onClick={handleSubmitTemplate}
              appearance="primary"
              loading={create.spinner || update.spinner}
            >
              Save Changes
            </Button>
          ) : (
            <Button onClick={handleEditTemplate} appearance="primary">
              Edit Template
            </Button>
          )}
          <Button onClick={handleCloseModal} appearance="subtle">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal open={deleteModalOpen} onClose={cancelDelete}>
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this template? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmDelete} appearance="primary" color="red" loading={deleteState.spinner}>
            Delete
          </Button>
          <Button onClick={cancelDelete} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default NotificationTemplates;
