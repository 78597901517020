import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = "deletePackage";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    deletePackage: null,
  };
}

export const deletePackage = createAsyncThunk('deletePackage', async (id, { rejectWithValue }) => {
    try {
        const response = await api.delete(`/super/api/package/delete/${id}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const deletePackageSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetDeletePackage: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.deletePackage = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(deletePackage.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(deletePackage.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.deletePackage = action.payload;
            })
            .addCase(deletePackage.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetDeletePackage } = deletePackageSlice.actions;
export const deletePackageReducer = deletePackageSlice.reducer;
