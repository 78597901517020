import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'userNotifications';

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        data: null,
    };
}

const initialState = {
    list: createInitialState(),
};

// Get User Notifications
export const getUserNotifications = createAsyncThunk(
    'userNotifications/get',
    async (userId, { rejectWithValue }) => {
        try {
            const response = await api.get(`/super/api/user-notification/get/${userId}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const userNotificationsSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetState: (state, action) => {
            const { type } = action.payload;
            state[type] = createInitialState();
        },
    },
    extraReducers: builder => {
        // List reducers
        builder
            .addCase(getUserNotifications.pending, state => {
                state.list.spinner = true;
                state.list.error = false;
            })
            .addCase(getUserNotifications.fulfilled, (state, action) => {
                state.list.done = true;
                state.list.spinner = false;
                state.list.error = false;
                state.list.data = action.payload;
            })
            .addCase(getUserNotifications.rejected, (state, action) => {
                state.list.done = false;
                state.list.spinner = false;
                state.list.error = action.payload;
            })
    },
});

export const { resetState } = userNotificationsSlice.actions;
export const userNotificationsReducer = userNotificationsSlice.reducer;
