import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationGroups,
  createNotificationGroup,
  updateNotificationGroup,
  deleteNotificationGroup,
  getNotificationGroupDetail,
  resetState,
} from "../../store/notification/group.slice";
import {
  Table,
  IconButton,
  Container,
  Header,
  Content,
  Button,
  Modal,
  Form,
  Input,
  Notification,
  toaster,
} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";

const { Column, HeaderCell, Cell } = Table;

function NotificationGroups() {
  const dispatch = useDispatch();
  const list = useSelector(state => state.notificationGroup.list);
  const create = useSelector(state => state.notificationGroup.create);
  const update = useSelector(state => state.notificationGroup.update);
  const detail = useSelector(state => state.notificationGroup.detail);
  const deleteState = useSelector(state => state.notificationGroup.delete);

  const [groupModal, setGroupModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentGroup, setCurrentGroup] = useState({
    id: "",
    name: "",
    description: "",
    condition: "",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [loadingGroupId, setLoadingGroupId] = useState(null);

  useEffect(() => {
    dispatch(getNotificationGroups({ pageSize: 10, pageNumber: 1 }));
  }, [dispatch]);

  useEffect(() => {
    if (detail.done || detail.error) {
      setLoadingGroupId(null);
      if (detail.done && detail.data) {
        setCurrentGroup(detail.data.result.data);
        setGroupModal(true);
      }
    }
  }, [detail.done, detail.error, detail.data]);

  useEffect(() => {
    if (create.done || update.done) {
      toaster.push(
        <Notification type="success" header="Success">
          Group {currentGroup.id ? 'updated' : 'added'} successfully
        </Notification>,
        { placement: 'topEnd' }
      );
      handleCloseModal();
      dispatch(getNotificationGroups({ pageSize: 10, pageNumber: 1 }));
      dispatch(resetState({ type: 'create' }));
      dispatch(resetState({ type: 'update' }));
    } else if (create.error || update.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to {currentGroup.id ? 'update' : 'add'} group: {create.error || update.error}
        </Notification>,
        { placement: 'topEnd' }
      );
    }
  }, [create, update, currentGroup.id, dispatch]);

  useEffect(() => {
    if (deleteState.done) {
      toaster.push(
        <Notification type="success" header="Success">
        Group deleted successfully
      </Notification>,
      { placement: 'topEnd' }
    );
    setDeleteModalOpen(false);
    dispatch(getNotificationGroups({ pageSize: 10, pageNumber: 1 }));
    dispatch(resetState({ type: 'delete' }));
  } else if (deleteState.error) {
    toaster.push(
      <Notification type="error" header="Error">
        Failed to delete group: {deleteState.error}
      </Notification>,
      { placement: 'topEnd' }
    );
    setDeleteModalOpen(false);
  }
  }, [deleteState, dispatch]);

  const handleAddGroup = () => {
    setEditMode(true);
    setCurrentGroup({
      id: "",
      name: "",
      description: "",
      condition: "",
    });
    setGroupModal(true);
  };

  const handleCloseModal = () => {
    setGroupModal(false);
    setEditMode(false);
    setCurrentGroup({
      id: "",
      name: "",
      description: "",
      condition: "",
    });
  };

  const handleInputChange = (value, name) => {
    setCurrentGroup(prev => ({ ...prev, [name]: value }));
  };

  const handleViewGroup = (groupId) => {
    setEditMode(false);
    setLoadingGroupId(groupId);
    dispatch(resetState({ type: 'detail' }));
    dispatch(getNotificationGroupDetail(groupId));
  };

  const handleEditGroup = () => {
    setEditMode(true);
  };

  const handleSubmitGroup = () => {
    if (currentGroup.id) {
      dispatch(updateNotificationGroup(currentGroup));
    } else {
      dispatch(createNotificationGroup(currentGroup));
    }
  };

  const handleDeleteGroup = (groupId) => {
    setGroupToDelete(groupId);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (groupToDelete) {
      dispatch(deleteNotificationGroup(groupToDelete));
      setGroupToDelete(null);
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setGroupToDelete(null);
  };

  return (
    <Container>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
          <h4>Notification Groups</h4>
          <IconButton icon={<PlusIcon />} appearance="primary" onClick={handleAddGroup}>
            Add Group
          </IconButton>
        </div>
      </Header>

      <Content>
        <Table
          height={window.innerHeight - 200}
          data={list.data?.result?.data || []}
          loading={list.spinner}
          wordWrap="break-word"
          rowHeight={60}
        >
          <Column width={200} flexGrow={1}>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column width={200} flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column width={300} flexGrow={2}>
            <HeaderCell>Description</HeaderCell>
            <Cell dataKey="description" />
          </Column>
          <Column width={300} flexGrow={2}>
            <HeaderCell>Condition</HeaderCell>
            <Cell dataKey="condition" />
          </Column>
          <Column width={100} fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <IconButton
                    icon={<EditIcon />}
                    size="sm"
                    appearance="primary"
                    loading={loadingGroupId === rowData.id}
                    onClick={() => handleViewGroup(rowData.id)}
                  />
                  <IconButton
                    icon={<TrashIcon />}
                    size="sm"
                    color="red"
                    onClick={() => handleDeleteGroup(rowData.id)}
                  />
                </span>
              )}
            </Cell>
          </Column>
        </Table>
      </Content>

      <Modal open={groupModal} onClose={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title>
            {editMode ? "Edit Group" : "View Group"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control
                name="name"
                value={currentGroup.name || ""}
                onChange={(value) => handleInputChange(value, "name")}
                disabled={!editMode}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Input
                as="textarea"
                rows={3}
                name="description"
                value={currentGroup.description || ""}
                onChange={(value) => handleInputChange(value, "description")}
                disabled={!editMode}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Condition</Form.ControlLabel>
              <Input
                as="textarea"
                rows={3}
                name="condition"
                value={currentGroup.condition || ""}
                onChange={(value) => handleInputChange(value, "condition")}
                disabled={!editMode}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {editMode ? (
            <Button
              onClick={handleSubmitGroup}
              appearance="primary"
              loading={create.spinner || update.spinner}
            >
              Save Changes
            </Button>
          ) : (
            <Button onClick={handleEditGroup} appearance="primary">
              Edit Group
            </Button>
          )}
          <Button onClick={handleCloseModal} appearance="subtle">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal open={deleteModalOpen} onClose={cancelDelete}>
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this group? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmDelete} appearance="primary" color="red" loading={deleteState.spinner}>
            Delete
          </Button>
          <Button onClick={cancelDelete} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default NotificationGroups;
