import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "getUserPackages";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getUserPackages: null,
  };
}
export const getUserPackages = createAsyncThunk(
  "getUserPackages",
  async ({ userId }, rejectWithValue) => {
    try {
      const response = await api.get(`/super/api/user-packages/get/${userId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

const getUserPackagesSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetGetUserPackages: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getUserPackages = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserPackages.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getUserPackages.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getUserPackages = action.payload;
      })
      .addCase(getUserPackages.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetGetUserPackages } = getUserPackagesSlice.actions;
export const getUserPackagesReducer = getUserPackagesSlice.reducer;
