import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'notification';

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        data: null,
    };
}

const initialState = {
    list: createInitialState(),
    create: createInitialState(),
    update: createInitialState(),
    detail: createInitialState(),
    delete: createInitialState(),
};

// Create Notification
export const createNotification = createAsyncThunk(
    'notification/create',
    async (notificationData, { rejectWithValue }) => {
        try {
            const response = await api.post('/super/api/notification/create', notificationData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Update Notification
export const updateNotification = createAsyncThunk(
    'notification/update',
    async (notificationData, { rejectWithValue }) => {
        try {
            const response = await api.put('/super/api/notification/update', notificationData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get Notifications
export const getNotifications = createAsyncThunk(
    'notification/getAll',
    async ({ pageNumber, pageSize }, { rejectWithValue }) => {
        try {
            const response = await api.get(`/super/api/notification/get?pageNumber=${pageNumber}&pageSize=${pageSize}`);
            return response.data;   
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Delete Notification
export const deleteNotification = createAsyncThunk(
    'notification/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/super/api/notification/delete/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get Notification Detail
export const getNotificationDetail = createAsyncThunk(
    'notification/getDetail',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/super/api/notification/detail/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const notificationSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetState: (state, action) => {
            const { type } = action.payload;
            state[type] = createInitialState();
        },
    },
    extraReducers: builder => {
        // List reducers
        builder
            .addCase(getNotifications.pending, state => {
                state.list.spinner = true;
                state.list.error = false;
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.list.done = true;
                state.list.spinner = false;
                state.list.error = false;
                state.list.data = action.payload;
            })
            .addCase(getNotifications.rejected, (state, action) => {
                state.list.done = false;
                state.list.spinner = false;
                state.list.error = action.payload;
            })

        // Create reducers
        builder
            .addCase(createNotification.pending, state => {
                state.create.spinner = true;
                state.create.error = false;
            })
            .addCase(createNotification.fulfilled, (state, action) => {
                state.create.done = true;
                state.create.spinner = false;
                state.create.error = false;
                state.create.data = action.payload;
            })
            .addCase(createNotification.rejected, (state, action) => {
                state.create.done = false;
                state.create.spinner = false;
                state.create.error = action.payload;
            })

        // Update reducers
        builder
            .addCase(updateNotification.pending, state => {
                state.update.spinner = true;
                state.update.error = false;
            })
            .addCase(updateNotification.fulfilled, (state, action) => {
                state.update.done = true;
                state.update.spinner = false;
                state.update.error = false;
                state.update.data = action.payload;
            })
            .addCase(updateNotification.rejected, (state, action) => {
                state.update.done = false;
                state.update.spinner = false;
                state.update.error = action.payload;
            })

        // Detail reducers
        builder
            .addCase(getNotificationDetail.pending, state => {
                state.detail.spinner = true;
                state.detail.error = false;
            })
            .addCase(getNotificationDetail.fulfilled, (state, action) => {
                state.detail.done = true;
                state.detail.spinner = false;
                state.detail.error = false;
                state.detail.data = action.payload;
            })
            .addCase(getNotificationDetail.rejected, (state, action) => {
                state.detail.done = false;
                state.detail.spinner = false;
                state.detail.error = action.payload;
            })

        // Delete reducers
        builder
            .addCase(deleteNotification.pending, state => {
                state.delete.spinner = true;
                state.delete.error = false;
            })
            .addCase(deleteNotification.fulfilled, (state, action) => {
                state.delete.done = true;
                state.delete.spinner = false;
                state.delete.error = false;
                state.delete.data = action.payload;
            })
            .addCase(deleteNotification.rejected, (state, action) => {
                state.delete.done = false;
                state.delete.spinner = false;
                state.delete.error = action.payload;
            })
    },
});

export const { resetState } = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;
