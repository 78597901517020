import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'addFeedbackOption';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        addFeedbackOption: null,
    };
}

    
export const addFeedbackOption = createAsyncThunk('addFeedbackOption', async ({ value, feedbackTypeId }, { rejectWithValue }) => {
    try {
        const response = await api.post('/super/api/feedback/option/create', { value, feedbackTypeId });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const addFeedbackOptionSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetaddFeedbackOption: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.addFeedbackOption = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(addFeedbackOption.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(addFeedbackOption.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.addFeedbackOption = action.payload;
            })
            .addCase(addFeedbackOption.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetaddFeedbackOption } = addFeedbackOptionSlice.actions;
export const addFeedbackOptionReducer = addFeedbackOptionSlice.reducer;