import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbackTypes } from "../../store/general-feedback/getFeedbackTypes.slice";
import { getFeedbackOptions } from "../../store/general-feedback/getFeedbackOptions.slice";
import { updateFeedbackOption } from "../../store/general-feedback/updateFeedbackOption.slice";
import { deleteFeedbackOption, resetDeleteFeedbackOption } from "../../store/general-feedback/deleteFeedbackOption.slice";
import { addFeedbackOption } from "../../store/general-feedback/createFeedbackOption.slice";
import { createFeedbackType, resetcreateFeedbackType } from "../../store/general-feedback/createFeedbackType.slice";
import { deleteFeedbackType, resetDeleteFeedbackType } from "../../store/general-feedback/deleteFeedbackType.slice";
import { Table, Button, Modal, Form, Input, InputNumber, Toggle, SelectPicker, Notification, toaster, IconButton, Container, Header, Content } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";

const { Column, HeaderCell, Cell } = Table;

function FeedbackSettings() {
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [feedbackOptions, setFeedbackOptions] = useState([]);
  const [newOption, setNewOption] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newTypeName, setNewTypeName] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState(null);
  const [deleteTypeModalOpen, setDeleteTypeModalOpen] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState(null);
  const [deleteOptionModalOpen, setDeleteOptionModalOpen] = useState(false);

  const dispatch = useDispatch();

  const getFeedbackTypesState = useSelector((state) => state.getFeedbackTypes);
  const getFeedbackOptionsState = useSelector((state) => state.getFeedbackOptions);
  const updateFeedbackOptionState = useSelector((state) => state.updateFeedbackOption);
  const deleteFeedbackOptionState = useSelector((state) => state.deleteFeedbackOption);
  const addFeedbackOptionState = useSelector((state) => state.addFeedbackOption);
  const createFeedbackTypeState = useSelector((state) => state.createFeedbackType);
  const deleteFeedbackTypeState = useSelector((state) => state.deleteFeedbackType);

  useEffect(() => {
    dispatch(getFeedbackTypes());
  }, [dispatch]);

  useEffect(() => {
    if (getFeedbackTypesState.done) {
      setFeedbackTypes(
        getFeedbackTypesState.getFeedbackTypes.result.feedbackTypes
      );
    }
  }, [getFeedbackTypesState]);

  useEffect(() => {
    if (selectedFeedbackType) {
      dispatch(getFeedbackOptions(selectedFeedbackType));
    }
  }, [dispatch, selectedFeedbackType]);

  useEffect(() => {
    if (getFeedbackOptionsState.done) {
      setFeedbackOptions(getFeedbackOptionsState.getFeedbackOptions.result.feedbackOptions);
    }
  }, [getFeedbackOptionsState]);

  useEffect(() => {
    if (updateFeedbackOptionState.done || deleteFeedbackOptionState.done) {
      dispatch(getFeedbackOptions(selectedFeedbackType));
    }
  }, [updateFeedbackOptionState, deleteFeedbackOptionState]);

  useEffect(() => {
    if (addFeedbackOptionState.done) {
      dispatch(getFeedbackOptions(selectedFeedbackType));
    }
  }, [addFeedbackOptionState]);

  useEffect(() => {
    if (createFeedbackTypeState.done) {
      handleCloseCreateModal();
      dispatch(getFeedbackTypes());
      dispatch(resetcreateFeedbackType());
      toaster.push(
        <Notification type="success" header="Success">
          Feedback type created successfully
        </Notification>,
        { placement: 'topEnd' }
      );
    } else if (createFeedbackTypeState.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to create feedback type: {createFeedbackTypeState.error.errorMessage}
        </Notification>,
        { placement: 'topEnd' }
      );
      dispatch(resetcreateFeedbackType());
    }
  }, [createFeedbackTypeState]);

  useEffect(() => {
    if (deleteFeedbackTypeState.done) {
      toaster.push(
        <Notification type="success" header="Success">
          Feedback type deleted successfully
        </Notification>,
        { placement: 'topEnd' }
      );
      dispatch(getFeedbackTypes());
      dispatch(resetDeleteFeedbackType());
    } else if (deleteFeedbackTypeState.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to delete feedback type: {deleteFeedbackTypeState.error.errorMessage}
        </Notification>,
        { placement: 'topEnd' }
      );
      dispatch(resetDeleteFeedbackType());
    }
  }, [deleteFeedbackTypeState, dispatch]);

  useEffect(() => {
    if (deleteFeedbackOptionState.done) {
      toaster.push(
        <Notification type="success" header="Success">
          Feedback option deleted successfully
        </Notification>,
        { placement: 'topEnd' }
      );
      dispatch(getFeedbackOptions(selectedFeedbackType));
      dispatch(resetDeleteFeedbackOption());
    } else if (deleteFeedbackOptionState.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to delete feedback option: {deleteFeedbackOptionState.error}
        </Notification>,
        { placement: 'topEnd' }
      );
    }
  }, [deleteFeedbackOptionState, dispatch, selectedFeedbackType]);

  const handleEdit = (feedbackType) => {
    setSelectedFeedbackType(feedbackType);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFeedbackType(null);
    setNewOption("");
  };

  const handleUpdateOption = (id, value) => {
    dispatch(updateFeedbackOption({ id, value }));
  };

  const handleDeleteOption = (id) => {
    setOptionToDelete(id);
    setDeleteOptionModalOpen(true);
  };

  const confirmDeleteOption = () => {
    if (optionToDelete) {
      dispatch(deleteFeedbackOption(optionToDelete));
      setDeleteOptionModalOpen(false);
      setOptionToDelete(null);
    }
  };

  const cancelDeleteOption = () => {
    setDeleteOptionModalOpen(false);
    setOptionToDelete(null);
  };

  const handleAddOption = () => {
    if (newOption.trim()) {
      dispatch(addFeedbackOption({ feedbackTypeId: selectedFeedbackType, value: newOption }))
        .then(() => {
          toaster.push(
            <Notification type="success" header="Success">
              New option added successfully
            </Notification>,
            { placement: 'topEnd' }
          );
          setNewOption(''); // Clear the input after successful addition
        })
        .catch((error) => {
          toaster.push(
            <Notification type="error" header="Error">
              Failed to add new option: {error.message}
            </Notification>,
            { placement: 'topEnd' }
          );
        });
    }
  };

  const handleOpenCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setNewTypeName("");
  };

  const handleCreateType = () => {
    if (newTypeName.trim()) {
      dispatch(createFeedbackType({ name: newTypeName }));
    }
  };

  const handleDeleteType = (id) => {
    setTypeToDelete(id);
    setDeleteTypeModalOpen(true);
  };

  const confirmDeleteType = () => {
    if (typeToDelete) {
      dispatch(deleteFeedbackType(typeToDelete));
      setDeleteTypeModalOpen(false);
      setTypeToDelete(null);
    }
  };

  const cancelDeleteType = () => {
    setDeleteTypeModalOpen(false);
    setTypeToDelete(null);
  };

  return (
    <Container>
      <Header>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}>
          <h4>Feedback Types</h4>
          <IconButton
            icon={<PlusIcon />}
            appearance="primary"
            onClick={handleOpenCreateModal}
          >
            Add Feedback Type
          </IconButton>
        </div>
      </Header>

      <Content>
        <Table
          height={400}
          data={feedbackTypes}
          loading={getFeedbackTypesState.spinner}
          wordWrap="break-word"
          rowHeight={60}
        >
          <Column width={200} flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column width={200} flexGrow={1}>
            <HeaderCell>Created At</HeaderCell>
            <Cell>{(rowData) => new Date(rowData.created_at).toLocaleString()}</Cell>
          </Column>
          <Column width={200} flexGrow={1}>
            <HeaderCell>Updated At</HeaderCell>
            <Cell>{(rowData) => new Date(rowData.updated_at).toLocaleString()}</Cell>
          </Column>
          <Column width={120} fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <IconButton
                    icon={<EditIcon />}
                    size="sm"
                    onClick={() => handleEdit(rowData.id)}
                  />
                  <IconButton
                    icon={<TrashIcon />}
                    size="sm"
                    color="red"
                    onClick={() => handleDeleteType(rowData.id)}
                  />
                </span>
              )}
            </Cell>
          </Column>
        </Table>
      </Content>

      <Modal open={showModal} onClose={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title>Feedback Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            loading={getFeedbackOptionsState.spinner}
            height={300} 
            data={feedbackOptions} 
            autoHeight
          >
            <Column flexGrow={1}>
              <HeaderCell>Option</HeaderCell>
              <Cell dataKey="value" />
            </Column>
            <Column width={120}>
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-around', 
                    alignItems: 'center',
                    height: '100%'
                  }}>
                    <IconButton
                      icon={<EditIcon />}
                      size="sm"
                      onClick={() => handleUpdateOption(rowData.id, prompt("New value:", rowData.value))}
                    />
                    <IconButton
                      icon={<TrashIcon />}
                      size="sm"
                      color="red"
                      onClick={() => handleDeleteOption(rowData.id)}
                    />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel style={{ marginTop: 10 }}>New Option</Form.ControlLabel>
              <div style={{ display: 'flex', marginTop: '10px' }}>
                <Input
                  value={newOption}
                  onChange={setNewOption}
                  style={{ flex: 1, marginRight: '10px' }}
                />
                <Button 
                  loading={addFeedbackOptionState.spinner} 
                  appearance="primary" 
                  onClick={handleAddOption}
                >
                  Add
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal open={showCreateModal} onClose={handleCloseCreateModal}>
        <Modal.Header>
          <Modal.Title>Create New Feedback Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel>Type Name</Form.ControlLabel>
              <Input
                value={newTypeName}
                onChange={setNewTypeName}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            loading={createFeedbackTypeState.spinner}
            onClick={handleCreateType} 
            appearance="primary"
          >
            Create
          </Button>
          <Button onClick={handleCloseCreateModal} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal open={deleteTypeModalOpen} onClose={cancelDeleteType}>
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this feedback type? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmDeleteType} appearance="primary" color="red" loading={deleteFeedbackTypeState.spinner}>
            Delete
          </Button>
          <Button onClick={cancelDeleteType} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal open={deleteTypeModalOpen} onClose={cancelDeleteType}>
        <Modal.Header>
          <Modal.Title>Confirm Delete Feedback Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this feedback type? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmDeleteType} appearance="primary" color="red" loading={deleteFeedbackTypeState.spinner}>
            Delete
          </Button>
          <Button onClick={cancelDeleteType} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal open={deleteOptionModalOpen} onClose={cancelDeleteOption}>
        <Modal.Header>
          <Modal.Title>Confirm Delete Feedback Option</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this feedback option? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmDeleteOption} appearance="primary" color="red" loading={deleteFeedbackOptionState.spinner}>
            Delete
          </Button>
          <Button onClick={cancelDeleteOption} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default FeedbackSettings;
