import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'notificationTemplate';

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        data: null,
    };
}

const initialState = {
    list: createInitialState(),
    create: createInitialState(),
    update: createInitialState(),
    detail: createInitialState(),
    delete: createInitialState(),
};

// Create Notification Template
export const createNotificationTemplate = createAsyncThunk(
    'notificationTemplate/create',
    async (templateData, { rejectWithValue }) => {
        try {
            const response = await api.post('/super/api/notification/template/create', templateData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Update Notification Template
export const updateNotificationTemplate = createAsyncThunk(
    'notificationTemplate/update',
    async (templateData, { rejectWithValue }) => {
        try {
            const response = await api.put('/super/api/notification/template/update', templateData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get Notification Templates
export const getNotificationTemplates = createAsyncThunk(
    'notificationTemplate/getAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/super/api/notification/template/get');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Delete Notification Template
export const deleteNotificationTemplate = createAsyncThunk(
    'notificationTemplate/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/super/api/notification/template/delete/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get Notification Template Detail
export const getNotificationTemplateDetail = createAsyncThunk(
    'notificationTemplate/getDetail',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/super/api/notification/template/detail/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const notificationTemplateSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetState: (state, action) => {
            const { type } = action.payload;
            state[type] = createInitialState();
        },
    },
    extraReducers: builder => {
        // List reducers
        builder
            .addCase(getNotificationTemplates.pending, state => {
                state.list.spinner = true;
                state.list.error = false;
            })
            .addCase(getNotificationTemplates.fulfilled, (state, action) => {
                state.list.done = true;
                state.list.spinner = false;
                state.list.error = false;
                state.list.data = action.payload;
            })
            .addCase(getNotificationTemplates.rejected, (state, action) => {
                state.list.done = false;
                state.list.spinner = false;
                state.list.error = action.payload;
            })

        // Create reducers
        builder
            .addCase(createNotificationTemplate.pending, state => {
                state.create.spinner = true;
                state.create.error = false;
            })
            .addCase(createNotificationTemplate.fulfilled, (state, action) => {
                state.create.done = true;
                state.create.spinner = false;
                state.create.error = false;
                state.create.data = action.payload;
            })
            .addCase(createNotificationTemplate.rejected, (state, action) => {
                state.create.done = false;
                state.create.spinner = false;
                state.create.error = action.payload;
            })

        // Update reducers
        builder
            .addCase(updateNotificationTemplate.pending, state => {
                state.update.spinner = true;
                state.update.error = false;
            })
            .addCase(updateNotificationTemplate.fulfilled, (state, action) => {
                state.update.done = true;
                state.update.spinner = false;
                state.update.error = false;
                state.update.data = action.payload;
            })
            .addCase(updateNotificationTemplate.rejected, (state, action) => {
                state.update.done = false;
                state.update.spinner = false;
                state.update.error = action.payload;
            })

        // Detail reducers
        builder
            .addCase(getNotificationTemplateDetail.pending, state => {
                state.detail.spinner = true;
                state.detail.error = false;
            })
            .addCase(getNotificationTemplateDetail.fulfilled, (state, action) => {
                state.detail.done = true;
                state.detail.spinner = false;
                state.detail.error = false;
                state.detail.data = action.payload;
            })
            .addCase(getNotificationTemplateDetail.rejected, (state, action) => {
                state.detail.done = false;
                state.detail.spinner = false;
                state.detail.error = action.payload;
            })

        // Delete reducers
        builder
            .addCase(deleteNotificationTemplate.pending, state => {
                state.delete.spinner = true;
                state.delete.error = false;
            })
            .addCase(deleteNotificationTemplate.fulfilled, (state, action) => {
                state.delete.done = true;
                state.delete.spinner = false;
                state.delete.error = false;
                state.delete.data = action.payload;
            })
            .addCase(deleteNotificationTemplate.rejected, (state, action) => {
                state.delete.done = false;
                state.delete.spinner = false;
                state.delete.error = action.payload;
            })
    },
});

export const { resetState } = notificationTemplateSlice.actions;
export const notificationTemplateReducer = notificationTemplateSlice.reducer;