import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'deleteLesson';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        deleteLesson: null,
    };
}

export const deleteLesson = createAsyncThunk('deleteLesson', async (id, { rejectWithValue }) => {
    try {
        const response = await api.delete(`/super/api/lesson/delete/${id}`);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const deleteLessonSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetDeleteLesson: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.deleteLesson = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(deleteLesson.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(deleteLesson.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.deleteLesson = action.payload;
            })
            .addCase(deleteLesson.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetDeleteLesson } = deleteLessonSlice.actions;
export const deleteLessonReducer = deleteLessonSlice.reducer;
