import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "getFeedbacksByTypeId";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getFeedbacksByTypeId: null,
  };
}

export const getFeedbacksByTypeId = createAsyncThunk(
  "getFeedbacksByTypeId",
  async ({ feedbackTypeId }, rejectWithValue) => {
    try {
      const response = await api.get(`/super/api/feedback/get/by-type/${feedbackTypeId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

const getFeedbacksByTypeIdSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetgetFeedbacksByTypeId: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getFeedbacksByTypeId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbacksByTypeId.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getFeedbacksByTypeId.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getFeedbacksByTypeId = action.payload;
      })
      .addCase(getFeedbacksByTypeId.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetgetFeedbacksByTypeId } = getFeedbacksByTypeIdSlice.actions;
export const getFeedbacksByTypeIdReducer = getFeedbacksByTypeIdSlice.reducer;
