import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationInfos, resetGetApplicationInfos } from '../../store/application-info/getApplicationInfos.slice';
import { createApplicationInfo, resetCreateApplicationInfo } from '../../store/application-info/createApplicationInfo.slice';
import { updateApplicationInfo, resetUpdateApplicationInfo } from '../../store/application-info/updateApplicationInfo.slice';
import { deleteApplicationInfo, resetDeleteApplicationInfo } from '../../store/application-info/deleteApplicationInfo.slice';
import { Table, IconButton, Container, Header, Content, Button, Modal, Form, InputNumber, Toggle, Notification, toaster } from 'rsuite';
import PlusIcon from "@rsuite/icons/Plus";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";

const { Column, HeaderCell, Cell } = Table;

function ApplicationInfos() {
    const dispatch = useDispatch();
    const getApplicationInfosState = useSelector((state) => state.getApplicationInfos);
    const createApplicationInfoState = useSelector((state) => state.createApplicationInfo);
    const updateApplicationInfoState = useSelector((state) => state.updateApplicationInfo);
    const deleteApplicationInfoState = useSelector((state) => state.deleteApplicationInfo);
    
    const [appInfoModal, setAppInfoModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentAppInfo, setCurrentAppInfo] = useState({
        id: '',
        iosAppVersion: '',
        androidAppVersion: '',
        iosForceUpdate: false,
        androidForceUpdate: false,
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [appInfoToDelete, setAppInfoToDelete] = useState(null);

    useEffect(() => {
        dispatch(getApplicationInfos());
    }, [dispatch]);

    const applicationInfos = getApplicationInfosState?.getApplicationInfos?.result?.applicationInfos || [];

    const handleAddAppInfo = () => {
        setEditMode(true);
        setCurrentAppInfo({
            iosAppVersion: '',
            androidAppVersion: '',
            iosForceUpdate: false,
            androidForceUpdate: false,
        });
        setAppInfoModal(true);
    };

    const handleCloseModal = () => {
        setAppInfoModal(false);
        setEditMode(false);
        setCurrentAppInfo({
            id: '',
            iosAppVersion: '',
            androidAppVersion: '',
            iosForceUpdate: false,
            androidForceUpdate: false,
        });
    };

    const handleInputChange = (value, name) => {
        setCurrentAppInfo({ ...currentAppInfo, [name]: value });
    };

    const handleViewAppInfo = (appInfo) => {
        setEditMode(false);
        setCurrentAppInfo(appInfo);
        setAppInfoModal(true);
    };

    const handleEditAppInfo = () => {
        setEditMode(true);
    };

    const handleSubmitAppInfo = () => {
        if (currentAppInfo.id) {
            // Mevcut kaydı güncelle
            dispatch(updateApplicationInfo({
                id: currentAppInfo.id,
                iosAppVersion: currentAppInfo.iosAppVersion,
                androidAppVersion: currentAppInfo.androidAppVersion,
                iosForceUpdate: currentAppInfo.iosForceUpdate,
                androidForceUpdate: currentAppInfo.androidForceUpdate
            }));
        } else {
            // Yeni kayıt ekle
            dispatch(createApplicationInfo({
                iosAppVersion: currentAppInfo.iosAppVersion,
                androidAppVersion: currentAppInfo.androidAppVersion,
                iosForceUpdate: currentAppInfo.iosForceUpdate,
                androidForceUpdate: currentAppInfo.androidForceUpdate
            }));
        }
    };

    const handleDeleteAppInfo = (appInfoId) => {
        setAppInfoToDelete(appInfoId);
        setDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        if (appInfoToDelete) {
            dispatch(deleteApplicationInfo(appInfoToDelete));
        }
    };

    useEffect(() => {
        if (deleteApplicationInfoState.done) {
            setDeleteModalOpen(false);
            setAppInfoToDelete(null);
        }
    }, [deleteApplicationInfoState.done]);

    const cancelDelete = () => {
        setDeleteModalOpen(false);
        setAppInfoToDelete(null);
    };

    useEffect(() => {
        if (createApplicationInfoState.done || updateApplicationInfoState.done) {
            toaster.push(
                <Notification type="success" header="Success">
                    Application info {currentAppInfo.id ? 'updated' : 'created'} successfully
                </Notification>,
                { placement: 'topEnd' }
            );
            handleCloseModal();
            dispatch(getApplicationInfos());
            dispatch(resetCreateApplicationInfo());
            dispatch(resetUpdateApplicationInfo());
        }
    }, [createApplicationInfoState.done, updateApplicationInfoState.done]);

    useEffect(() => {
        if (deleteApplicationInfoState.done) {
            toaster.push(
                <Notification type="success" header="Success">
                    Application info deleted successfully
                </Notification>,
                { placement: 'topEnd' }
            );
            dispatch(getApplicationInfos());
            dispatch(resetDeleteApplicationInfo());
        }
    }, [deleteApplicationInfoState.done]);

    return (
        <Container>
            <Header>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 20,
                }}>
                    <h4>Application Info</h4>
                    <IconButton
                        icon={<PlusIcon />}
                        appearance="primary"
                        onClick={handleAddAppInfo}
                    >
                        Add Application Info
                    </IconButton>
                </div>
            </Header>

            <Content>
                <Table
                    height={window.innerHeight - 200}
                    data={applicationInfos}
                    loading={getApplicationInfosState.spinner}
                    wordWrap="break-word"
                    rowHeight={60}
                >
                    <Column width={200} flexGrow={1}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={150} flexGrow={1}>
                        <HeaderCell>iOS Version</HeaderCell>
                        <Cell dataKey="iosAppVersion" />
                    </Column>
                    <Column width={150} flexGrow={1}>
                        <HeaderCell>Android Version</HeaderCell>
                        <Cell dataKey="androidAppVersion" />
                    </Column>
                    <Column width={150} flexGrow={1}>
                        <HeaderCell>iOS Force Update</HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <Toggle
                                    checked={rowData.iosForceUpdate}
                                    disabled
                                />
                            )}
                        </Cell>
                    </Column>
                    <Column width={150} flexGrow={1}>
                        <HeaderCell>Android Force Update</HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <Toggle
                                    checked={rowData.androidForceUpdate}
                                    disabled
                                />
                            )}
                        </Cell>
                    </Column>
                    <Column width={200} flexGrow={1}>
                        <HeaderCell>Created At</HeaderCell>
                        <Cell>
                            {(rowData) => new Date(rowData.createdAt).toLocaleString()}
                        </Cell>
                    </Column>
                    <Column width={200} flexGrow={1}>
                        <HeaderCell>Updated At</HeaderCell>
                        <Cell>
                            {(rowData) => new Date(rowData.updatedAt).toLocaleString()}
                        </Cell>
                    </Column>
                    <Column width={100} fixed="right">
                        <HeaderCell>Actions</HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <IconButton
                                        icon={<EditIcon />}
                                        size="sm"
                                        onClick={() => handleViewAppInfo(rowData)}
                                    />
                                    <IconButton
                                        icon={<TrashIcon />}
                                        size="sm"
                                        color="red"
                                        onClick={() => handleDeleteAppInfo(rowData.id)}
                                    />
                                </span>
                            )}
                        </Cell>
                    </Column>
                </Table>
            </Content>

            <Modal open={appInfoModal} onClose={handleCloseModal} size="md">
                <Modal.Header>
                    <Modal.Title>{editMode ? 'Edit Application Info' : 'View Application Info'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group>
                            <Form.ControlLabel>iOS App Version</Form.ControlLabel>
                            <Form.Control 
                                name="iosAppVersion" 
                                value={currentAppInfo.iosAppVersion} 
                                onChange={(value) => handleInputChange(value, 'iosAppVersion')} 
                                disabled={!editMode} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Android App Version</Form.ControlLabel>
                            <Form.Control 
                                name="androidAppVersion" 
                                value={currentAppInfo.androidAppVersion} 
                                onChange={(value) => handleInputChange(value, 'androidAppVersion')} 
                                disabled={!editMode} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>iOS Force Update</Form.ControlLabel>
                            <Toggle 
                                checked={currentAppInfo.iosForceUpdate} 
                                onChange={(value) => handleInputChange(value, 'iosForceUpdate')} 
                                disabled={!editMode}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Android Force Update</Form.ControlLabel>
                            <Toggle 
                                checked={currentAppInfo.androidForceUpdate} 
                                onChange={(value) => handleInputChange(value, 'androidForceUpdate')} 
                                disabled={!editMode}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {editMode ? (
                        <Button onClick={handleSubmitAppInfo} appearance="primary" loading={updateApplicationInfoState.spinner || createApplicationInfoState.spinner}>
                            Save Changes
                        </Button>
                    ) : (
                        <Button onClick={handleEditAppInfo} appearance="primary">
                            Edit Application Info
                        </Button>
                    )}
                    <Button onClick={handleCloseModal} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal open={deleteModalOpen} onClose={cancelDelete}>
                <Modal.Header>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this application info? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button loading={deleteApplicationInfoState.spinner} onClick={confirmDelete} appearance="primary" color="red">
                        Delete
                    </Button>
                    <Button onClick={cancelDelete} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default ApplicationInfos;
