import { configureStore } from '@reduxjs/toolkit';


import { createCodeReducer } from './auth/createCode.slice';
import { verifyCodeReducer } from './auth/verifyCode.slice';
import { addExamReducer } from './exam/addExam.slice';
import { getExamsReducer } from './exam/getExams.slice';
import { deleteExamReducer } from './exam/deleteExam.slice';
import { updateExamReducer } from './exam/updateExam.slice';
import { addLessonReducer } from './lesson/addLesson.slice';
import { getLessonsReducer } from './lesson/getLessons.slice';
import { updateLessonReducer } from './lesson/updateLesson.slice';
import { deleteLessonReducer } from './lesson/deleteLesson.slice';  
import { getSubjectsReducer } from './subject/getSubjects.slice';
import { addFeedbackOptionReducer } from './general-feedback/createFeedbackOption.slice';
import { deleteFeedbackOptionReducer } from './general-feedback/deleteFeedbackOption.slice';
import { deleteFeedbackTypeReducer } from './general-feedback/deleteFeedbackType.slice';
import { deleteGeneralFeedbackReducer } from './general-feedback/deleteGeneralFeedback.slice';
import { getFeedbackOptionsReducer } from './general-feedback/getFeedbackOptions.slice';
import { getFeedbackTypesReducer } from './general-feedback/getFeedbackTypes.slice';
import { getGeneralFeedbacksReducer } from './general-feedback/getGeneralFeedbacks.slice';
import { updateFeedbackOptionReducer } from './general-feedback/updateFeedbackOption.slice';
import { createFeedbackTypeReducer } from './general-feedback/createFeedbackType.slice';
import { getFeedbacksByTypeIdReducer } from './general-feedback/getFeedbacksByTypeId.slice';
import { getUserReducer } from './user/getUser.slice';
import { addPackageReducer } from './package/addPackage.slice';
import { updatePackageReducer } from './package/updatePackage.slice';
import { deletePackageReducer } from './package/deletePackage.slice';
import { getPackagesReducer } from './package/getPackages.slice';
import { addUserPackageReducer } from './package/addUserPackage.slice';
import { getUserPackagesReducer } from './package/getUserPackages.slice';   
import { getPackageDetailReducer } from './package/getPackageDetail.slice';
import { getUsersReducer } from './user/getUsers.slice';
import { createApplicationInfoReducer } from './application-info/createApplicationInfo.slice';
import { updateApplicationInfoReducer } from './application-info/updateApplicationInfo.slice';
import { deleteApplicationInfoReducer } from './application-info/deleteApplicationInfo.slice';
import { getApplicationInfosReducer } from './application-info/getApplicationInfos.slice';
import { notificationGroupReducer } from './notification/group.slice';
import { notificationTemplateReducer } from './notification/template.slice';
import { notificationScheduleReducer } from './notification/schedule.slice';
import { notificationReducer } from './notification/notification.slice';
import { userNotificationsReducer } from './notification/userNotifications.slice';
import { entityReducer } from './entity/entity.slice';
import { levelOfEducationReducer } from './level-of-education/levelOfEducation.slice';
import { fileReducer } from './file/file.slice';

export const store = configureStore({
    reducer: {
        createCode: createCodeReducer,
        verifyCode: verifyCodeReducer,
        addExam: addExamReducer,
        getExams: getExamsReducer,
        deleteExam: deleteExamReducer,
        updateExam: updateExamReducer,
        addLesson: addLessonReducer,
        getLessons: getLessonsReducer,
        updateLesson: updateLessonReducer,
        deleteLesson: deleteLessonReducer,
        getSubjects: getSubjectsReducer,
        addFeedbackOption: addFeedbackOptionReducer,
        createFeedbackType: createFeedbackTypeReducer,
        deleteFeedbackOption: deleteFeedbackOptionReducer,
        deleteFeedbackType: deleteFeedbackTypeReducer,
        deleteGeneralFeedback: deleteGeneralFeedbackReducer,
        getFeedbackOptions: getFeedbackOptionsReducer,
        getFeedbackTypes: getFeedbackTypesReducer,
        getGeneralFeedbacks: getGeneralFeedbacksReducer,
        updateFeedbackOption: updateFeedbackOptionReducer,
        getFeedbacksByTypeId: getFeedbacksByTypeIdReducer,
        getUser: getUserReducer,
        addPackage: addPackageReducer,
        updatePackage: updatePackageReducer,
        deletePackage: deletePackageReducer,
        getPackages: getPackagesReducer,
        addUserPackage: addUserPackageReducer,
        getUserPackages: getUserPackagesReducer,
        getPackageDetail: getPackageDetailReducer,
        getUsers: getUsersReducer,
        createApplicationInfo: createApplicationInfoReducer,
        updateApplicationInfo: updateApplicationInfoReducer,
        deleteApplicationInfo: deleteApplicationInfoReducer,
        getApplicationInfos: getApplicationInfosReducer,
        notificationGroup: notificationGroupReducer,
        notificationTemplate: notificationTemplateReducer,
        notificationSchedule: notificationScheduleReducer,
        notification: notificationReducer,
        userNotifications: userNotificationsReducer,
        entity: entityReducer,
        levelOfEducation: levelOfEducationReducer,
        file: fileReducer,
    },
});
