import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'file';

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        data: null,
    };
}

const initialState = {
    list: createInitialState(),
    create: createInitialState(),
    delete: createInitialState(),
};

// Create File Upload
export const createFileUpload = createAsyncThunk(
    'file/create',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await api.post('/super/api/file-upload/create', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Bir hata oluştu');
        }
    }
);

// Get All Files
export const getFiles = createAsyncThunk(
    'file/getAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/super/api/file-upload/get');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Bir hata oluştu');
        }
    }
);

// Delete File
export const deleteFile = createAsyncThunk(
    'file/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/super/api/file-upload/delete/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Bir hata oluştu');
        }
    }
);

const fileSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetState: (state, action) => {
            const { type } = action.payload;
            state[type] = createInitialState();
        },
    },
    extraReducers: builder => {
        // List reducers
        builder
            .addCase(getFiles.pending, state => {
                state.list.spinner = true;
                state.list.error = false;
            })
            .addCase(getFiles.fulfilled, (state, action) => {
                state.list.done = true;
                state.list.spinner = false;
                state.list.error = false;
                state.list.data = action.payload;
            })
            .addCase(getFiles.rejected, (state, action) => {
                state.list.done = false;
                state.list.spinner = false;
                state.list.error = action.payload;
            })

        // Create reducers
        builder
            .addCase(createFileUpload.pending, state => {
                state.create.spinner = true;
                state.create.error = false;
            })
            .addCase(createFileUpload.fulfilled, (state, action) => {
                state.create.done = true;
                state.create.spinner = false;
                state.create.error = false;
                state.create.data = action.payload;
            })
            .addCase(createFileUpload.rejected, (state, action) => {
                state.create.done = false;
                state.create.spinner = false;
                state.create.error = action.payload;
            })

        // Delete reducers
        builder
            .addCase(deleteFile.pending, state => {
                state.delete.spinner = true;
                state.delete.error = false;
            })
            .addCase(deleteFile.fulfilled, (state, action) => {
                state.delete.done = true;
                state.delete.spinner = false;
                state.delete.error = false;
                state.delete.data = action.payload;
            })
            .addCase(deleteFile.rejected, (state, action) => {
                state.delete.done = false;
                state.delete.spinner = false;
                state.delete.error = action.payload;
            })
    },
});

export const { resetState } = fileSlice.actions;
export const fileReducer = fileSlice.reducer;
