import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  IconButton,
  Container,
  Header,
  Content,
  Cascader,
} from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import { getExams } from "../../store/exam/getExams.slice";
import { getLessons } from "../../store/lesson/getLessons.slice";
import { getSubjects } from "../../store/subject/getSubjects.slice";

const { Column, HeaderCell, Cell } = Table;

function Subjects() {
  const dispatch = useDispatch();
  const getExamsState = useSelector((state) => state.getExams);
  const getLessonsState = useSelector((state) => state.getLessons);
  const getSubjectsState = useSelector((state) => state.getSubjects);

  const [cascaderData, setCascaderData] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);

  useEffect(() => {
    dispatch(getExams());
  }, [dispatch]);

  useEffect(() => {
    if (getExamsState.getExams?.result?.targetExams) {
      const exams = getExamsState.getExams.result.targetExams;
      console.log("Exams:", exams);
      setCascaderData(exams.map(exam => ({
        value: exam.id,
        label: exam.name,
        children: []
      })));
    }
  }, [getExamsState.getExams]);

  console.log(getSubjectsState)

  const handleCascaderSelect = (value, selectedPath) => {
    if (selectedPath.length === 1) {
      // Exam selected
      setSelectedExam(value);
      setSelectedLesson(null);
      dispatch(getLessons(value.value));
    } else if (selectedPath.length === 2) {
      // Lesson selected
      setSelectedLesson(value);
      dispatch(getSubjects(value.value));
    }
  };

  const handleCascaderSearch = (searchKeyword, items, path) => {
    const lowerCaseSearchKeyword = searchKeyword.toLowerCase();
  
    return items.filter(item => {
      return item.label.toLowerCase().includes(lowerCaseSearchKeyword);
    });
  };

  useEffect(() => {
    if (getLessonsState.getLessons?.result?.lessons) {
      const lessons = getLessonsState.getLessons.result.lessons;
      console.log("Lessons:", lessons);

      setCascaderData(prevData => {
        const newData = [...prevData];
        console.log("Previous Cascader Data:", newData);
        console.log("Lessons length:", lessons.length);

        if (lessons.length > 0) {
          console.log("First lesson:", lessons[0]);
          // targetExamId yerine targetExamID kullanıyoruz
          const selectedExam = newData.find(exam => exam.value === lessons[0].targetExamID);
          console.log("Selected Exam:", selectedExam);
      
          if (selectedExam) {
            selectedExam.children = lessons.map(lesson => ({
              value: lesson.id,
              label: lesson.name,
              children: []
            }));
          } else {
            console.log("No matching exam found for targetExamID:", lessons[0].targetExamID);
            // Eğer eşleşme bulunamazsa, tüm dersleri ilk sınava ekleyelim
            if (newData.length > 0) {
              newData[0].children = lessons.map(lesson => ({
                value: lesson.id,
                label: lesson.name,
                children: []
              }));
            }
          }
        } else {
          console.log("Lessons array is empty");
          newData.forEach(exam => {
            exam.children = [];
          });
        }

        return newData;
      });
    } else {
      console.log("getLessonsState.getLessons?.result?.lessons is undefined or null");
    }
  }, [getLessonsState.getLessons]);

  useEffect(() => {
    if (getSubjectsState.getSubjects?.result?.subjects) {
      const subjects = getSubjectsState.getSubjects.result.subjects;
      console.log("Subjects:", subjects); // Log subjects for debugging

      setCascaderData(prevData => {
        const newData = [...prevData];
        
        if (subjects.length > 0 && subjects[0].lessonId) {
          const selectedExam = newData.find(exam => 
            exam.children.some(lesson => lesson.value === subjects[0].lessonId)
          );
          
          if (selectedExam) {
            const selectedLesson = selectedExam.children.find(lesson => lesson.value === subjects[0].lessonId);
            if (selectedLesson) {
              selectedLesson.children = subjects.map(subject => ({
                value: subject.id,
                label: subject.name
              }));
            }
          } else {
            console.log("No matching exam or lesson found for subjects");
          }
        } else {
          console.log("Subjects array is empty or doesn't contain lessonId");
        }
        
        return newData;
      });
    } else {
      console.log("getSubjectsState.getSubjects?.result?.subjects is undefined or null");
    }
  }, [getSubjectsState.getSubjects]);

  const handleEditSubject = (subject) => {
    console.log("Edit subject:", subject);
  };

  const handleDeleteSubject = (subjectId) => {
    console.log("Delete subject:", subjectId);
  };

  return (
    <Container>
      <Header>
        <h2>Subjects</h2>
      </Header>

      <Content>
        <Cascader 
          data={cascaderData} 
          style={{ width: 500 }} 
          placeholder="Select Exam / Lesson"
          onSelect={handleCascaderSelect}
          onSearch={handleCascaderSearch}
          
        />

        {selectedExam && selectedLesson && getSubjectsState.getSubjects?.result?.subjects && (
          <Table autoHeight data={getSubjectsState.getSubjects.result.subjects} fluid style={{ marginTop: 40 }}>
            <Column width={220} fixed>
              <HeaderCell>Name</HeaderCell>
              <Cell dataKey="name" />
            </Column>

            <Column width={220}>
              <HeaderCell>Description</HeaderCell>
              <Cell dataKey="description" />
            </Column>

            <Column width={220}>
              <HeaderCell>Created At</HeaderCell>
              <Cell dataKey="createdAt" />
            </Column>

            <Column width={220}>
              <HeaderCell>Updated At</HeaderCell>
              <Cell dataKey="updatedAt" />
            </Column>

            <Column width={150} fixed="right">
              <HeaderCell>Actions</HeaderCell>
              <Cell>
                {(rowData) => (
                  <span>
                    <IconButton
                      icon={<EditIcon />}
                      size="sm"
                      onClick={() => handleEditSubject(rowData)}
                      style={{ marginRight: 10 }}
                    />
                    <IconButton
                      icon={<TrashIcon />}
                      size="sm"
                      onClick={() => handleDeleteSubject(rowData.id)}
                    />
                  </span>
                )}
              </Cell>
            </Column>
          </Table>
        )}
        {getSubjectsState.error && <div>Error: {getSubjectsState.error}</div>}
      </Content>
    </Container>
  );
}

export default Subjects;
