import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'addExam';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        addExam: null,
    };
}

    
export const addExam = createAsyncThunk('addExam', async ({ name, description,style }, { rejectWithValue }) => {
    try {
        const response = await api.post('/super/api/target-exam/add', { name, description,style });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const addExamSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetAddExam: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.addExam = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(addExam.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(addExam.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.addExam = action.payload;
            })
            .addCase(addExam.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetAddExam } = addExamSlice.actions;
export const addExamReducer = addExamSlice.reducer;
