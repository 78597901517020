import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useRoutes } from 'react-router-dom';
import './App.css';
import routes from './routes';
import { logout } from './store/auth/verifyCode.slice';
import 'rsuite/dist/rsuite.min.css';
import 'rsuite/styles/index.less';
//version 5.1.0
function App() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleUnauthorized = () => {
            dispatch(logout());
        };

        window.addEventListener('unauthorized', handleUnauthorized);

        return () => {
            window.removeEventListener('unauthorized', handleUnauthorized);
        };
    }, [navigate, dispatch]);

    return useRoutes(routes);
}

export default App;
