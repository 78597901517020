import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Modal, Form, Container, Header, Content } from "rsuite";
import { getFiles } from "../../store/file/file.slice";
import { createFileUpload, resetState } from "../../store/file/file.slice";
import { deleteFile } from "../../store/file/file.slice";
import PlusIcon from "@rsuite/icons/Plus";

const { Column, HeaderCell, Cell } = Table;

function Files() {
  const dispatch = useDispatch();
  const fileState = useSelector((state) => state.file);

  const isCreating = fileState.create.spinner;
  const isDeleting = fileState.delete.spinner;
  const isLoading = fileState.list.spinner;

  const [openAddModal, setOpenAddModal] = useState(false);
  const [newFile, setNewFile] = useState({
    file: null,
    fileName: "",
    forWhat: "",
    path: "",
  });
  const [deletingId, setDeletingId] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    dispatch(getFiles());
  }, [dispatch]);

  let data = [];
  if (fileState.list.done) {
    data = fileState.list.data?.result?.fileUploads || [];
  }

  const handleAddModalClose = () => {
    if (isCreating) return;
    setOpenAddModal(false);
    setNewFile({ file: null, fileName: "", forWhat: "", path: "" });
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    
    if (newFile.file) {
      formData.append('file', newFile.file);
    }
    formData.append('fileName', newFile.fileName);
    formData.append('forWhat', newFile.forWhat);
    formData.append('path', newFile.path);

    dispatch(createFileUpload(formData));
  };

  const handleDelete = (id) => {
    setDeletingId(id);
    dispatch(deleteFile(id));
  };

  useEffect(() => {
    if (fileState.create.done) {
      dispatch(getFiles());
      dispatch(resetState({ type: "create" }));
      setOpenAddModal(false);
    }
  }, [fileState.create.done, dispatch]);

  useEffect(() => {
    if (fileState.delete.done) {
      dispatch(getFiles());
      dispatch(resetState({ type: "delete" }));
      setDeletingId(null);
    }
  }, [fileState.delete.done, dispatch]);

  const handlePreviewOpen = (imageUrl) => {
    setPreviewImage(imageUrl);
  };

  const handlePreviewClose = () => {
    setPreviewImage(null);
  };

  return (
    <Container>
      <Header>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <h4>Files</h4>
          <Button
            icon={<PlusIcon />}
            appearance="primary"
            onClick={() => setOpenAddModal(true)}
          >
            Add File
          </Button>
        </div>
      </Header>

      <Content>
        <Table
          height={400}
          data={data}
          wordWrap="break-word"
          rowHeight={60}
          loading={isLoading}
        >
          <Column width={200} flexGrow={1}>
            <HeaderCell>File Name</HeaderCell>
            <Cell dataKey="fileName" />
          </Column>

          <Column width={200} flexGrow={1}>
            <HeaderCell>For What</HeaderCell>
            <Cell dataKey="forWhat" />
          </Column>

          <Column width={300} flexGrow={1}>
            <HeaderCell>File URL</HeaderCell>
            <Cell>
              {(rowData) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <div style={{ 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis', 
                    whiteSpace: 'nowrap',
                    flex: 1 
                  }}>
                    {rowData.fileUrl}
                  </div>
                  <Button
                    size="xs"
                    onClick={() => navigator.clipboard.writeText(rowData.fileUrl)}
                    appearance="ghost"
                  >
                    <i className="fa-regular fa-copy"></i>
                  </Button>
                </div>
              )}
            </Cell>
          </Column>
          <Column width={100}>
            <HeaderCell>Preview</HeaderCell>
            <Cell>
              {rowData => (
                <img 
                  width={50} 
                  src={rowData.fileUrl} 
                  alt="" 
                  style={{ cursor: 'pointer' }}
                  onClick={() => handlePreviewOpen(rowData.fileUrl)}
                />
              )}
            </Cell>
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Created At</HeaderCell>
            <Cell dataKey="createdAt" />
          </Column>

          <Column width={120} flexGrow={1} align="center" fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <Button
                  appearance="primary"
                  color="red"
                  onClick={() => handleDelete(rowData.id)}
                  loading={isDeleting && deletingId === rowData.id}
                  disabled={isDeleting}
                >
                  <i className="fa-solid fa-trash"></i>
                </Button>
              )}
            </Cell>
          </Column>
        </Table>

        <Modal
          open={openAddModal}
          onClose={handleAddModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Upload New File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group>
                <Form.ControlLabel>File</Form.ControlLabel>
                <Form.Control
                  name="file"
                  type="file"
                  accepter={FileUploader}
                  onChange={(value) => {
                    console.log("Selected file:", value);
                    setNewFile({ ...newFile, file: value });
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>File Name</Form.ControlLabel>
                <Form.Control
                  name="fileName"
                  value={newFile.fileName}
                  onChange={(value) => setNewFile({ ...newFile, fileName: value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>For What</Form.ControlLabel>
                <Form.Control
                  name="forWhat"
                  value={newFile.forWhat}
                  onChange={(value) => setNewFile({ ...newFile, forWhat: value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Path</Form.ControlLabel>
                <Form.Control
                  name="path"
                  value={newFile.path}
                  onChange={(value) => setNewFile({ ...newFile, path: value })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleFileUpload}
              appearance="primary"
              loading={isCreating}
              disabled={isCreating}
            >
              Upload
            </Button>
            <Button
              onClick={handleAddModalClose}
              appearance="subtle"
              disabled={isCreating}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          open={previewImage !== null}
          onClose={handlePreviewClose}
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>Image Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              maxHeight: '80vh',
              overflow: 'auto'
            }}>
              <img
                src={previewImage}
                alt="Preview"
                style={{ 
                  maxWidth: '100%', 
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handlePreviewClose} appearance="subtle">
              Close
            </Button>
            <Button 
              appearance="primary" 
              onClick={() => window.open(previewImage, '_blank')}
            >
              Open Original
            </Button>
          </Modal.Footer>
        </Modal>
      </Content>
    </Container>
  );
}

const FileUploader = React.forwardRef((props, ref) => (
  <input
    type="file"
    ref={ref}
    onChange={(event) => {
      const file = event.target.files?.[0];
      if (file) {
        props.onChange?.(file);
      }
    }}
  />
));

export default Files;
