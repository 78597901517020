import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'updateApplicationInfo';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        updateApplicationInfo: null,
    };
}

export const updateApplicationInfo = createAsyncThunk('updateApplicationInfo', async ({ id, iosAppVersion, androidAppVersion, iosForceUpdate, androidForceUpdate }, { rejectWithValue }) => {
    try {
        const response = await api.put(`/super/api/application-info/update`, { id, iosAppVersion, androidAppVersion, iosForceUpdate, androidForceUpdate });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const updateApplicationInfoSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetUpdateApplicationInfo: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.updateApplicationInfo = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(updateApplicationInfo.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(updateApplicationInfo.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.updateApplicationInfo = action.payload;
            })
            .addCase(updateApplicationInfo.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetUpdateApplicationInfo } = updateApplicationInfoSlice.actions;
export const updateApplicationInfoReducer = updateApplicationInfoSlice.reducer;
