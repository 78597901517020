import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationSchedules,
  createNotificationSchedule,
  updateNotificationSchedule,
  deleteNotificationSchedule,
  getNotificationScheduleDetail,
  resetState,
} from "../../store/notification/schedule.slice";
import {
  Table,
  IconButton,
  Container,
  Header,
  Content,
  Button,
  Modal,
  Form,
  DatePicker,
  SelectPicker,
  TagPicker,
  Notification,
  toaster,
  Input,
} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";

const { Column, HeaderCell, Cell } = Table;

const repeatTypes = [
  { value: 'daily', label: 'Günlük' },
  { value: 'weekly', label: 'Haftalık' },
  { value: 'monthly', label: 'Aylık' }
];

const weekDays = [
  { value: 0, label: 'Pazar' },
  { value: 1, label: 'Pazartesi' },
  { value: 2, label: 'Salı' },
  { value: 3, label: 'Çarşamba' },
  { value: 4, label: 'Perşembe' },
  { value: 5, label: 'Cuma' },
  { value: 6, label: 'Cumartesi' }
];

// Mevcut times değerlerini options formatına çeviren fonksiyon
const formatTimesAsOptions = (times) => {
  return times.map(time => ({
    label: time,
    value: time
  }));
};

function NotificationSchedules() {
  const dispatch = useDispatch();
  const list = useSelector(state => state.notificationSchedule.list);
  const create = useSelector(state => state.notificationSchedule.create);
  const update = useSelector(state => state.notificationSchedule.update);
  const detail = useSelector(state => state.notificationSchedule.detail);
  const deleteState = useSelector(state => state.notificationSchedule.delete);

  const [scheduleModal, setScheduleModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentSchedule, setCurrentSchedule] = useState({
    id: "",
    name: "",
    description: "",
    startDate: null,
    endDate: null,
    repeatType: "daily",
    repeatInterval: 1,
    weekDays: [],
    monthDays: [],
    times: [],
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [scheduleToDelete, setScheduleToDelete] = useState(null);
  const [loadingScheduleId, setLoadingScheduleId] = useState(null);

  // Times için options state'i
  const [timeOptions, setTimeOptions] = useState([]);

  useEffect(() => {
    dispatch(getNotificationSchedules());
  }, [dispatch]);

  useEffect(() => {
    setScheduleModal(false);
  }, []);

  useEffect(() => {
    if (detail.done || detail.error) {
      setLoadingScheduleId(null);
      if (detail.done && detail.data) {
        const schedule = detail.data.result.notificationSchedule;
        const times = Array.isArray(schedule.times) ? schedule.times : schedule.times?.split(',') || [];
        
        setTimeOptions(formatTimesAsOptions(times));
        
        setCurrentSchedule({
          ...schedule,
          startDate: new Date(schedule.startDate),
          endDate: new Date(schedule.endDate),
          times: times
        });
        setScheduleModal(true);
      }
    }
  }, [detail.done, detail.error, detail.data]);


  useEffect(() => {
    if (create.done || update.done) {
      toaster.push(
        <Notification type="success" header="Success">
          Schedule {currentSchedule.id ? 'updated' : 'added'} successfully
        </Notification>,
        { placement: 'topEnd' }
      );
      handleCloseModal();
      dispatch(getNotificationSchedules());
      dispatch(resetState({ type: 'create' }));
      dispatch(resetState({ type: 'update' }));
    } else if (create.error || update.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to {currentSchedule.id ? 'update' : 'add'} schedule
        </Notification>,
        { placement: 'topEnd' }
      );
    }
  }, [create, update, currentSchedule.id, dispatch]);

  // Delete effect handler
  useEffect(() => {
    if (deleteState.done) {
      toaster.push(
        <Notification type="success" header="Success">
          Schedule deleted successfully
        </Notification>,
        { placement: 'topEnd' }
      );
      setDeleteModalOpen(false);
      dispatch(getNotificationSchedules());
      dispatch(resetState({ type: 'delete' }));
    } else if (deleteState.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to delete schedule
        </Notification>,
        { placement: 'topEnd' }
      );
      setDeleteModalOpen(false);
    }
  }, [deleteState, dispatch]);

  const handleAddSchedule = () => {
    setEditMode(true);
    setCurrentSchedule({
      id: "",
      startDate: new Date(),
      endDate: new Date(),
      repeatType: "daily",
      repeatInterval: 1,
      weekDays: [],
      monthDays: [],
      times: [],
    });
    setScheduleModal(true);
  };

  const handleCloseModal = () => {
    setScheduleModal(false);
    setEditMode(false);
    setCurrentSchedule({
      id: "",
      startDate: null,
      endDate: null,
      repeatType: "daily",
      repeatInterval: 1,
      weekDays: [],
      monthDays: [],
      times: [],
    });
  };

  const handleInputChange = (value, name) => {
    if (name === "repeatType") {
      setCurrentSchedule(prev => ({
        ...prev,
        repeatType: value,
        weekDays: value === 'daily' || value === 'monthly' ? [] : prev.weekDays,
        monthDays: value === 'daily' || value === 'weekly' ? [] : prev.monthDays,
      }));
      return;
    }

    if (name === "times") {
      const newTime = value[value.length - 1];
      if (newTime && !timeOptions.find(opt => opt.value === newTime)) {
        setTimeOptions(prev => [...prev, { label: newTime, value: newTime }]);
      }
    }

    setCurrentSchedule(prev => ({ ...prev, [name]: value }));
  };

  const handleViewSchedule = (scheduleId) => {
    setEditMode(false);
    setLoadingScheduleId(scheduleId);
    dispatch(resetState({ type: 'detail' }));
    dispatch(getNotificationScheduleDetail(scheduleId));
  };

  const handleEditSchedule = () => {
    setEditMode(true);
  };

  const handleSubmitSchedule = () => {
    let isValid = true;
    let errorMessage = '';

    if (!currentSchedule.name?.trim()) {
      isValid = false;
      errorMessage = 'Name is required';
    } else if (!currentSchedule.times?.length) {
      isValid = false;
      errorMessage = 'At least one time must be selected';
    } else if (!currentSchedule.startDate || !currentSchedule.endDate) {
      isValid = false;
      errorMessage = 'Start and end dates are required';
    }

    switch (currentSchedule.repeatType) {
      case 'weekly':
        if (!currentSchedule.weekDays?.length) {
          isValid = false;
          errorMessage = 'Please select at least one week day for weekly schedules';
        }
        break;
      case 'monthly':
        if (!currentSchedule.monthDays?.length) {
          isValid = false;
          errorMessage = 'Please select at least one month day for monthly schedules';
        }
        break;
    }

    if (!isValid) {
      toaster.push(
        <Notification type="error" header="Validation Error">
          {errorMessage}
        </Notification>,
        { placement: 'topEnd' }
      );
      return;
    }

    const scheduleData = {
      ...currentSchedule,
      startDate: currentSchedule.startDate.toISOString(),
      endDate: currentSchedule.endDate.toISOString(),
      weekDays: currentSchedule.repeatType === 'weekly' ? currentSchedule.weekDays : [],
      monthDays: currentSchedule.repeatType === 'monthly' ? currentSchedule.monthDays : [],
    };

    if (currentSchedule.id) {
      dispatch(updateNotificationSchedule(scheduleData));
    } else {
      dispatch(createNotificationSchedule(scheduleData));
    }
  };

  const handleDeleteSchedule = (scheduleId) => {
    setScheduleToDelete(scheduleId);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (scheduleToDelete) {
      dispatch(deleteNotificationSchedule(scheduleToDelete));
      setScheduleToDelete(null);
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setScheduleToDelete(null);
  };

  return (
    <Container>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
          <h4>Notification Schedules</h4>
          <IconButton icon={<PlusIcon />} appearance="primary" onClick={handleAddSchedule}>
            Add Schedule
          </IconButton>
        </div>
      </Header>

      <Content>
        <Table
          height={window.innerHeight - 200}
          data={list.data?.result?.notificationSchedules || []}
          loading={list.spinner}
          wordWrap="break-word"
          rowHeight={60}
        >
          <Column width={200} flexGrow={1}>
            <HeaderCell>Start Date</HeaderCell>
            <Cell>
              {rowData => new Date(rowData.startDate).toLocaleDateString()}
            </Cell>
          </Column>
          <Column width={200} flexGrow={1}>
            <HeaderCell>End Date</HeaderCell>
            <Cell>
              {rowData => new Date(rowData.endDate).toLocaleDateString()}
            </Cell>
          </Column>
          <Column width={150} flexGrow={1}>
            <HeaderCell>Repeat Type</HeaderCell>
            <Cell dataKey="repeatType" />
          </Column>
          <Column width={150} flexGrow={2}>
            <HeaderCell>Times</HeaderCell>
            <Cell>
              {rowData => rowData.times.join(", ")}
            </Cell>
          </Column>
          <Column width={100} fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <IconButton
                    icon={<EditIcon />}
                    size="sm"
                    appearance="primary"
                    loading={loadingScheduleId === rowData.id}
                    onClick={() => handleViewSchedule(rowData.id)}
                  />
                  <IconButton
                    icon={<TrashIcon />}
                    size="sm"
                    color="red"
                    onClick={() => handleDeleteSchedule(rowData.id)}
                  />
                </span>
              )}
            </Cell>
          </Column>
        </Table>
      </Content>

      <Modal open={scheduleModal} onClose={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title>
            {editMode ? "Edit Schedule" : "View Schedule"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
          <Form.Group style={{ flex: 1 }}>
                <Form.ControlLabel>Name</Form.ControlLabel>
                <Input
                  value={currentSchedule.name}
                  onChange={(value) => handleInputChange(value, "name")}
                  disabled={!editMode}
                  block
                />
              </Form.Group>
              <Form.Group style={{ flex: 1 }}>
                <Form.ControlLabel>Description</Form.ControlLabel>
                <Input
                  value={currentSchedule.description}
                  onChange={(value) => handleInputChange(value, "description")}
                  disabled={!editMode}
                  block
                  as="textarea"
                  rows={3}
                />
              </Form.Group>
            <div style={{ display: 'flex', gap: '20px' }}>
              
              <Form.Group style={{ flex: 1 }}>
                <Form.ControlLabel>Start Date</Form.ControlLabel>
                <DatePicker
                  format="yyyy-MM-dd"
                  value={currentSchedule.startDate}
                  onChange={(value) => handleInputChange(value, "startDate")}
                  disabled={!editMode}
                  block
                />
              </Form.Group>
              <Form.Group style={{ flex: 1 }}>
                <Form.ControlLabel>End Date</Form.ControlLabel>
                <DatePicker
                  format="yyyy-MM-dd"
                  value={currentSchedule.endDate}
                  onChange={(value) => handleInputChange(value, "endDate")}
                  disabled={!editMode}
                  block
                />
              </Form.Group>
            </div>
            <Form.Group>
              <Form.ControlLabel>Repeat Type</Form.ControlLabel>
              <SelectPicker
                data={repeatTypes}
                value={currentSchedule.repeatType}
                onChange={(value) => handleInputChange(value, "repeatType")}
                disabled={!editMode}
                block
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Week Days</Form.ControlLabel>
              <TagPicker
                data={weekDays}
                value={currentSchedule.weekDays}
                onChange={(value) => handleInputChange(value, "weekDays")}
                disabled={!editMode || currentSchedule.repeatType !== 'weekly'}
                block
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Month Days</Form.ControlLabel>
              <TagPicker
                creatable
                data={Array.from({length: 31}, (_, i) => ({ label: `${i + 1}`, value: i + 1 }))}
                value={currentSchedule.monthDays}
                onChange={(value) => handleInputChange(value, "monthDays")}
                disabled={!editMode || currentSchedule.repeatType !== 'monthly'}
                block
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Times</Form.ControlLabel>
              <TagPicker
                creatable
                data={timeOptions}
                value={currentSchedule.times}
                onChange={(value) => handleInputChange(value, "times")}
                disabled={!editMode}
                block
                trigger={['Enter', 'Space', 'Comma']}
                placeholder="Enter time (e.g., 09:00)"
                onCreate={(value) => {
                  const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
                  // if (!timeRegex.test(value)) {
                  //   toaster.push(
                  //     <Notification type="error" header="Invalid Format">
                  //       Please enter time in HH:mm format
                  //     </Notification>,
                  //     { placement: 'topEnd' }
                  //   );
                  //   return false;
                  // }
                  // Yeni değeri options'a ekle
                  setTimeOptions(prev => [...prev, { label: value, value }]);
                  return value;
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Repeat Interval</Form.ControlLabel>
              <Input 
                type="number" 
                min={1}
                value={currentSchedule.repeatInterval}
                onChange={(value) => handleInputChange(value, "repeatInterval")}
                disabled={!editMode}
                block
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {editMode ? (
            <Button
              onClick={handleSubmitSchedule}
              appearance="primary"
              loading={create.spinner || update.spinner}
            >
              Save Changes
            </Button>
          ) : (
            <Button onClick={handleEditSchedule} appearance="primary">
              Edit Schedule
            </Button>
          )}
          <Button onClick={handleCloseModal} appearance="subtle">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal open={deleteModalOpen} onClose={cancelDelete}>
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this schedule? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmDelete} appearance="primary" color="red" loading={deleteState.spinner}>
            Delete
          </Button>
          <Button onClick={cancelDelete} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default NotificationSchedules;
