import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'updateExam';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        updateExam: null,
    };
}

export const updateExam = createAsyncThunk('updateExam', async ({ id, data }, { rejectWithValue }) => {
    try {
        const response = await api.put(`/super/api/target-exam/update/${id}`, data);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || 'An error occurred');
    }
});

const updateExamSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetUpdateExam: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.updateExam = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(updateExam.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(updateExam.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.updateExam = action.payload;
            })
            .addCase(updateExam.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetUpdateExam } = updateExamSlice.actions;
export const updateExamReducer = updateExamSlice.reducer;
