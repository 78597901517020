import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPackages } from '../../store/package/getPackages.slice';
import { addPackage, resetAddPackage } from '../../store/package/addPackage.slice';
import { getPackageDetail, resetGetPackageDetail } from '../../store/package/getPackageDetail.slice';
import { updatePackage, resetUpdatePackage } from '../../store/package/updatePackage.slice';
import { deletePackage, resetDeletePackage } from '../../store/package/deletePackage.slice';
import { Table, IconButton, Container, Header, Content, Button, Modal, Form, Input, InputNumber, Toggle, SelectPicker, Notification, toaster } from 'rsuite';
import PlusIcon from "@rsuite/icons/Plus";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import CodeMirror from '@uiw/react-codemirror';
import { json } from "@codemirror/lang-json";
import { dracula } from "@uiw/codemirror-theme-dracula";

const { Column, HeaderCell, Cell } = Table;

function Packages() {
    const dispatch = useDispatch();
    const getPackagesState = useSelector((state) => state.getPackages);
    const addPackageState = useSelector((state) => state.addPackage);
    const getPackageDetailState = useSelector((state) => state.getPackageDetail);
    const updatePackageState = useSelector((state) => state.updatePackage);
    const deletePackageState = useSelector((state) => state.deletePackage);
    const [packageModal, setPackageModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentPackage, setCurrentPackage] = useState({
        id: '',
        name: '',
        description: '',
        price: 0,
        style: '{}',
        totalRights: 0,
        scope: '',
        validityPeriod: '',
        validityDuration: 0,
        isDefault: false,
        sortOrder: 0,
        isHidden: false,
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [packageToDelete, setPackageToDelete] = useState(null);

    const validityPeriodOptions = [
        { label: 'Day', value: 'day' },
        { label: 'Week', value: 'week' },
        { label: 'Month', value: 'month' },
        { label: 'Year', value: 'year' },
    ];

    useEffect(() => {
        setDeleteModalOpen(false);
    }, []);

    useEffect(() => {
        dispatch(getPackages());
    }, [dispatch]);

    useEffect(() => {
        if (getPackageDetailState.done && getPackageDetailState.getPackageDetail?.result?.response) {
            const packageData = getPackageDetailState.getPackageDetail.result.response;
            setCurrentPackage({
                ...packageData,
                style: packageData.style ? JSON.stringify(packageData.style, null, 2) : '{}'
            });
            setPackageModal(true);
        }
    }, [getPackageDetailState.done]);


    const packages = getPackagesState?.getPackages?.result?.response || [];


    const handleAddPackage = () => {
        setEditMode(true);
        setCurrentPackage({
            name: '',
            description: '',
            price: 0,
            style: '{}',
            totalRights: 0,
            scope: '',
            validityPeriod: '',
            validityDuration: 0,
            isDefault: false,
            sortOrder: 0,
            isHidden: false,
        });
        setPackageModal(true);
    };

    const handleCloseModal = () => {
        setPackageModal(false);
        setEditMode(false);
        setCurrentPackage({
            id: '',
            name: '',
            description: '',
            price: 0,
            style: '{}',
            totalRights: 0,
            scope: '',
            validityPeriod: '',
            validityDuration: 0,
            isDefault: false,
            sortOrder: 0,
            isHidden: false,
        });
        dispatch(resetGetPackageDetail());
    };

    const handleInputChange = (value, name) => {
        setCurrentPackage({ ...currentPackage, [name]: value });
    };

    const handleStyleChange = (value) => {
        setCurrentPackage({ ...currentPackage, style: value });
    };

    const handleViewPackage = (packageId) => {
        setEditMode(false);
        dispatch(getPackageDetail({ id: packageId }));
    };

    const handleEditPackage = () => {
        setEditMode(true);
    };

    const handleSubmitPackage = () => {
        const formattedPackage = {
            name: currentPackage.name,
            description: currentPackage.description,
            price: parseFloat(currentPackage.price),
            style: currentPackage.style ? JSON.parse(currentPackage.style) : null,
            totalRights: parseInt(currentPackage.totalRights, 10),
            scope: currentPackage.scope,
            validityPeriod: currentPackage.validityPeriod,
            validityDuration: parseInt(currentPackage.validityDuration, 10),
            isDefault: currentPackage.isDefault,
            sortOrder: parseInt(currentPackage.sortOrder, 10),
            isHidden: currentPackage.isHidden,
        };

        if (currentPackage.id) {
            // Mevcut paketi güncelle
            dispatch(updatePackage({ ...formattedPackage, id: currentPackage.id }));
        } else {
            // Yeni paket ekle
            dispatch(addPackage(formattedPackage));
        }
    };

    useEffect(() => {
        if (updatePackageState.done) {
            toaster.push(
                <Notification type="success" header="Success">
                    Package updated successfully
                </Notification>,
                { placement: 'topEnd' }
            );
            handleCloseModal();
            dispatch(getPackages());
            dispatch(resetUpdatePackage());
        } else if (updatePackageState.error) {
            toaster.push(
                <Notification type="error" header="Error">
                    Failed to update package: {updatePackageState.error}
                </Notification>,
                { placement: 'topEnd' }
            );
        }
    }, [updatePackageState.done, updatePackageState.error, dispatch]);

    useEffect(() => {
        if (deletePackageState.done) {
            toaster.push(
                <Notification type="success" header="Success">
                    Package deleted successfully
                </Notification>,
                { placement: 'topEnd' }
            );
            setDeleteModalOpen(false);
            dispatch(getPackages());
            dispatch(resetDeletePackage());
        } else if (deletePackageState.error) {
            toaster.push(
                <Notification type="error" header="Error">
                    Failed to delete package: {deletePackageState.error}
                </Notification>,
                { placement: 'topEnd' }
            );
        }
    }, [deletePackageState.done, deletePackageState.error, dispatch]);

    useEffect(() => {
        if (addPackageState.done) {
            toaster.push(
                <Notification type="success" header="Success">
                    Package added successfully
                </Notification>,
                { placement: 'topEnd' }
            );
            handleCloseModal();
            dispatch(getPackages());
            dispatch(resetAddPackage());
        }
    }, [addPackageState.done]);

    const handleDeletePackage = (packageId) => {
        setPackageToDelete(packageId);
        setDeleteModalOpen(true);
    };

    const confirmDelete = () => {
        if (packageToDelete) {
            dispatch(deletePackage(packageToDelete));
            setPackageToDelete(null);
        }
    };

    const cancelDelete = () => {
        setDeleteModalOpen(false);
        setPackageToDelete(null);
    };

    return (
        <Container>
            <Header>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 20,
                }}>
                    <h4>Packages</h4>
                    <IconButton
                        icon={<PlusIcon />}
                        appearance="primary"
                        onClick={handleAddPackage}
                    >
                        Add Package
                    </IconButton>
                </div>
            </Header>

            <Content>
                <Table
                    height={window.innerHeight - 200}
                    data={packages}
                    loading={getPackagesState.spinner}
                    wordWrap="break-word"
                    rowHeight={60}
                >
                    <Column width={200} flexGrow={1}>
                        <HeaderCell>ID</HeaderCell>
                        <Cell dataKey="id" />
                    </Column>
                    <Column width={150} flexGrow={1}>
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="name" />
                    </Column>
                    <Column width={300} flexGrow={2}>
                        <HeaderCell>Description</HeaderCell>
                        <Cell dataKey="description" />
                    </Column>
                    <Column width={100} flexGrow={1}>
                        <HeaderCell>Price</HeaderCell>
                        <Cell dataKey="price" />
                    </Column>
                    <Column width={100} fixed="right">
                        <HeaderCell>Actions</HeaderCell>
                        <Cell>
                            {(rowData) => (
                                <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <IconButton
                                        icon={<EditIcon />}
                                        size="sm"
                                        onClick={() => handleViewPackage(rowData.id)}
                                    />
                                    <IconButton
                                        icon={<TrashIcon />}
                                        size="sm"
                                        color="red"
                                        onClick={() => handleDeletePackage(rowData.id)}
                                    />
                                </span>
                            )}
                        </Cell>
                    </Column>
                </Table>
            </Content>

            <Modal open={packageModal} onClose={handleCloseModal} size="lg">
                <Modal.Header>
                    <Modal.Title>{editMode ? 'Edit Package' : 'View Package'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group>
                            <Form.ControlLabel>Name</Form.ControlLabel>
                            <Form.Control name="name" value={currentPackage.name} onChange={(value) => handleInputChange(value, 'name')} disabled={!editMode} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Description</Form.ControlLabel>
                            <Form.Control rows={3} name="description" value={currentPackage.description} onChange={(value) => handleInputChange(value, 'description')} componentClass="textarea" disabled={!editMode} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Price</Form.ControlLabel>
                            <InputNumber 
                                name="price" 
                                value={currentPackage.price} 
                                onChange={(value) => handleInputChange(value, 'price')} 
                                step={0.01} 
                                disabled={!editMode}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Style</Form.ControlLabel>
                            <CodeMirror
                                value={currentPackage.style}
                                height="200px"
                                theme={dracula}
                                extensions={[json()]}
                                onChange={handleStyleChange}
                                editable={editMode}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Total Rights</Form.ControlLabel>
                            <InputNumber 
                                disabled={!editMode}
                                name="totalRights" 
                                value={currentPackage.totalRights} 
                                onChange={(value) => handleInputChange(value, 'totalRights')} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Scope</Form.ControlLabel>
                            <Form.Control disabled={!editMode} name="scope" value={currentPackage.scope} onChange={(value) => handleInputChange(value, 'scope')} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Validity Period</Form.ControlLabel>
                            <SelectPicker 
                                data={validityPeriodOptions}
                                value={currentPackage.validityPeriod}
                                disabled={!editMode}
                                onChange={(value) => handleInputChange(value, 'validityPeriod')}
                                style={{ width: '100%' }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Validity Duration</Form.ControlLabel>
                            <InputNumber 
                                name="validityDuration" 
                                value={currentPackage.validityDuration} 
                                onChange={(value) => handleInputChange(value, 'validityDuration')} 
                                disabled={!editMode}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Is Default</Form.ControlLabel>
                            <Toggle disabled={!editMode} checked={currentPackage.isDefault} onChange={(value) => handleInputChange(value, 'isDefault')} />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Sort Order</Form.ControlLabel>
                            <InputNumber 
                                disabled={!editMode}
                                name="sortOrder" 
                                value={currentPackage.sortOrder} 
                                onChange={(value) => handleInputChange(value, 'sortOrder')} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Is Hidden</Form.ControlLabel>
                            <Toggle disabled={!editMode} checked={currentPackage.isHidden} onChange={(value) => handleInputChange(value, 'isHidden')} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {editMode ? (
                        <Button onClick={handleSubmitPackage} appearance="primary" loading={updatePackageState.spinner || addPackageState.spinner}>
                            Save Changes
                        </Button>
                    ) : (
                        <Button onClick={handleEditPackage} appearance="primary">
                            Edit Package
                        </Button>
                    )}
                    <Button onClick={handleCloseModal} appearance="subtle">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal open={deleteModalOpen} onClose={cancelDelete}>
                <Modal.Header>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this package? This action cannot be undone.
                </Modal.Body>
                <Modal.Footer>
                    <Button loading={deletePackageState.spinner} onClick={confirmDelete} appearance="primary" color="red">
                        Delete
                    </Button>
                    <Button onClick={cancelDelete} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Packages;
