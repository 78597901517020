import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "getLessons";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getLessons: null,
  };
}

export const getLessons = createAsyncThunk(
  "getLessons",
  async (examId, rejectWithValue) => {
    try {
      const response = await api.get(`/api/lesson/get/${examId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);



const getLessonsSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetGetLessons: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getLessons = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLessons.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getLessons.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getLessons = action.payload;
      })
      .addCase(getLessons.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetGetLessons } = getLessonsSlice.actions;
export const getLessonsReducer = getLessonsSlice.reducer;
