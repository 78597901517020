import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  SelectPicker,
  Button,
  Table,
  Form,
  IconButton,
  Nav,
  Container,
  Header,
  Content,
} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import { getExams } from "../../store/exam/getExams.slice";
import { getLessons } from "../../store/lesson/getLessons.slice";
import { addLesson, resetAddLesson } from "../../store/lesson/addLesson.slice";
import { resetUpdateLesson, updateLesson } from "../../store/lesson/updateLesson.slice";
import CodeMirror from "@uiw/react-codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { json } from "@codemirror/lang-json";
import { deleteLesson, resetDeleteLesson } from "../../store/lesson/deleteLesson.slice";
import Swal from "sweetalert2";

const { Column, HeaderCell, Cell } = Table;

function Lessons() {
  const getExamsState = useSelector((state) => state.getExams);
  const getLessonsState = useSelector((state) => state.getLessons);
  const addLessonState = useSelector((state) => state.addLesson);
  const updateLessonState = useSelector((state) => state.updateLesson);
  const deleteLessonState = useSelector((state) => state.deleteLesson);
  const dispatch = useDispatch();

  const [activeExam, setActiveExam] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [newLesson, setNewLesson] = useState({
    name: "",
    description: "",
    targetExamID: activeExam || "",
    style: "",
    sortOrder: 0, // Changed from order to sortOrder
  });
  const [code, setCode] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editingLesson, setEditingLesson] = useState(null);
  const [editingStyle, setEditingStyle] = useState("");

  useEffect(() => {
    dispatch(getExams());
  }, [dispatch]);

  useEffect(() => {
    if (activeExam) {
      dispatch(getLessons(activeExam));
    }
  }, [dispatch, activeExam]);

  useEffect(() => {
    if (addLessonState.done) {
      dispatch(getLessons(activeExam));
      dispatch(resetAddLesson());
      setOpenAddModal(false);
    }
  }, [addLessonState.done, dispatch, activeExam]);

  const exams = getExamsState?.getExams?.result?.targetExams || [];
  const lessons = getLessonsState?.getLessons?.result?.lessons || [];

  const handleExamSelect = (targetExamID) => {
    setActiveExam(targetExamID);
  };

  const handleAddLesson = () => {
    if (!newLesson.targetExamID) {
      // Eğer targetExamID seçilmemişse, bir hata mesajı gösterilebilir
      alert("Please select a target exam");
      return;
    }

    const lessonToAdd = {
      ...newLesson,
      targetExamID: newLesson.targetExamID || activeExam,
    };
    dispatch(addLesson(lessonToAdd));
    setNewLesson({
      name: "",
      description: "",
      targetExamID: activeExam || "",
      style: "",
      sortOrder: 0, // Changed from order to sortOrder
    });
    setOpenAddModal(false);
  };

  const handleSaveCode = () => {
    console.log("Saving code:", code);
    // Burada API çağrısı yapılabilir veya başka bir işlem gerçekleştirilebilir
  };

  const handleEditLesson = (lesson) => {
    setEditingLesson({
      id: lesson.id,
      name: lesson.name,
      description: lesson.description,
      targetExamID: lesson.targetExamId,
      style: typeof lesson.style === 'string' ? lesson.style : JSON.stringify(lesson.style, null, 2),
      sortOrder: lesson.sortOrder,
    });
    setEditingStyle(typeof lesson.style === 'string' ? lesson.style : JSON.stringify(lesson.style, null, 2));
    setEditModalOpen(true);
  };

  const handleSaveEdit = () => {

    const updatedLesson = {
      id: editingLesson.id,
      name: editingLesson.name,
      description: editingLesson.description,
      targetExamId: editingLesson.targetExamID,
      style: editingStyle,
      sortOrder: editingLesson.sortOrder,
    };
    dispatch(updateLesson(updatedLesson));
  };

  useEffect(() => {
    if (updateLessonState.done) {
      setEditModalOpen(false);
      setEditingLesson(null);
      dispatch(getLessons(activeExam));
      dispatch(resetUpdateLesson());
      
    }
  }, [updateLessonState.done, dispatch, activeExam]);

  useEffect(() => {
    if (deleteLessonState.done) {
      dispatch(getLessons(activeExam));
      dispatch(resetDeleteLesson());
      Swal.fire({
        title: "Lesson deleted",
        text: "Lesson deleted successfully",
        icon: "success",
        confirmButtonText: "OK",
      });
    }
  }, [deleteLessonState.done, dispatch, activeExam]);

  const handleDeleteLesson = (lessonId) => {
    dispatch(deleteLesson(lessonId));
  };

 


  return (
    <Container>
      <Header>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Nav
            appearance="tabs"
            activeKey={activeExam}
            onSelect={handleExamSelect}
          >
            {exams.map((exam) => (
              <Nav.Item key={exam.id} eventKey={exam.id}>
                {exam.name}
              </Nav.Item>
            ))}
          </Nav>
          <IconButton
            icon={<PlusIcon />}
            appearance="primary"
            onClick={() => setOpenAddModal(true)}
          >
            Add Lesson
          </IconButton>
        </div>
      </Header>

      <Content>
        {activeExam && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              <h4>
                Lessons for {exams.find((e) => e.id === activeExam)?.name}
              </h4>
            </div>
            <Table 
              height={400}
              loading={getLessonsState.spinner} 
              data={lessons} 
              wordWrap="break-word"
              rowHeight={60}
            >
              <Column width={200} flexGrow={1}>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="name" />
              </Column>

              <Column width={300} flexGrow={2}>
                <HeaderCell>Description</HeaderCell>
                <Cell dataKey="description" />
              </Column>

              <Column width={150} flexGrow={1}>
                <HeaderCell>Created At</HeaderCell>
                <Cell dataKey="createdAt" />
              </Column>

              <Column width={150} flexGrow={1}>
                <HeaderCell>Updated At</HeaderCell>
                <Cell dataKey="updatedAt" />
              </Column>

              <Column width={100} flexGrow={1}>
                <HeaderCell>Sort Order</HeaderCell>
                <Cell dataKey="sortOrder" />
              </Column>

              <Column width={150} fixed="right">
                <HeaderCell>Actions</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <span>
                      <IconButton
                        icon={<EditIcon />}
                        size="sm"
                        onClick={() => handleEditLesson(rowData)}
                        style={{ marginRight: 10 }}
                      />
                      <IconButton
                        icon={<TrashIcon />}
                        size="sm"
                        onClick={() => handleDeleteLesson(rowData.id)}
                      />
                    </span>
                  )}
                </Cell>
              </Column>
            </Table>
          
          </>
        )}

        <Modal open={openAddModal} onClose={() => setOpenAddModal(false)}>
          <Modal.Header>
            <Modal.Title>Add New Lesson</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group>
                <Form.ControlLabel>Target Exam</Form.ControlLabel>
                <SelectPicker
                  data={exams.map((exam) => ({
                    label: exam.name,
                    value: exam.id,
                  }))}
                  value={newLesson.targetExamID || activeExam}
                  onChange={(value) =>
                    setNewLesson({ ...newLesson, targetExamID: value })
                  }
                  block
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Name</Form.ControlLabel>
                <Form.Control
                  name="name"
                  value={newLesson.name}
                  onChange={(value) =>
                    setNewLesson({ ...newLesson, name: value })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Description</Form.ControlLabel>
                <Form.Control
                  name="description"
                  value={newLesson.description}
                  onChange={(value) =>
                    setNewLesson({ ...newLesson, description: value })
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Sort Order</Form.ControlLabel>
                <Form.Control
                  name="sortOrder"
                  type="number"
                  value={newLesson.sortOrder}
                  onChange={(value) =>
                    setNewLesson({ ...newLesson, sortOrder: parseInt(value) })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleAddLesson}
              appearance="primary"
              loading={addLessonState.spinner}
            >
              Add
            </Button>
            <Button onClick={() => setOpenAddModal(false)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Edit Modal'ını ekle */}
        <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <Modal.Header>
            <Modal.Title>Edit Lesson</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {editingLesson && (
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Target Exam</Form.ControlLabel>
                  <SelectPicker
                    data={exams.map((exam) => ({
                      label: exam.name,
                      value: exam.id,
                    }))}
                    value={editingLesson.targetExamID}
                    onChange={(value) =>
                      setEditingLesson({
                        ...editingLesson,
                        targetExamID: value,
                      })
                    }
                    block
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={editingLesson.name}
                    onChange={(value) =>
                      setEditingLesson({ ...editingLesson, name: value })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Description</Form.ControlLabel>
                  <Form.Control
                    name="description"
                    value={editingLesson.description}
                    onChange={(value) =>
                      setEditingLesson({ ...editingLesson, description: value })
                    }
                  />
                </Form.Group>
                
                <Form.Group>
                  <Form.ControlLabel>Style (JSON)</Form.ControlLabel>
                    <CodeMirror
                      value={editingStyle}
                      height="200px"
                      theme={dracula}
                      extensions={[json()]}
                      onChange={(value) => {
                        setEditingStyle(value);
                      }}
                    />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Sort Order</Form.ControlLabel>
                  <Form.Control
                    name="sortOrder"
                    type="number"
                    value={editingLesson?.sortOrder}
                    onChange={(value) =>
                      setEditingLesson({ ...editingLesson, sortOrder: parseInt(value) })
                    }
                  />
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button 
              onClick={handleSaveEdit} 
              appearance="primary"
              loading={updateLessonState.spinner}
            >
              Save Changes
            </Button>
            <Button onClick={() => setEditModalOpen(false)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Content>
    </Container>
  );
}

export default Lessons;
