import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Modal, Form, Container, Header, Content, Checkbox, CheckboxGroup } from "rsuite";
import { getLevelOfEducations } from "../../store/level-of-education/levelOfEducation.slice";
import { createLevelOfEducation } from "../../store/level-of-education/levelOfEducation.slice";
import { updateLevelOfEducation } from "../../store/level-of-education/levelOfEducation.slice";
import { deleteLevelOfEducation } from "../../store/level-of-education/levelOfEducation.slice";
import { resetState } from "../../store/level-of-education/levelOfEducation.slice";
import PlusIcon from "@rsuite/icons/Plus";
import { getExams } from "../../store/exam/getExams.slice";

const { Column, HeaderCell, Cell } = Table;

function LevelOfEducations() {
  const dispatch = useDispatch();
  const levelOfEducationState = useSelector((state) => state.levelOfEducation);
  const examsState = useSelector((state) => state.getExams);

  const [open, setOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editingEducation, setEditingEducation] = useState(null);
  const [newEducation, setNewEducation] = useState({ name: '' });

  useEffect(() => {
    dispatch(getLevelOfEducations());
  }, [dispatch]);

  useEffect(() => {
    if (levelOfEducationState.create.done) {
      dispatch(getLevelOfEducations());
      dispatch(resetState({ type: 'create' }));
      setOpenAddModal(false);
    }
  }, [levelOfEducationState.create.done, dispatch]);

  useEffect(() => {
    if (levelOfEducationState.update.done) {
      dispatch(getLevelOfEducations());
      dispatch(resetState({ type: 'update' }));
      setOpen(false);
    }
  }, [levelOfEducationState.update.done, dispatch]);

  useEffect(() => {
    if (levelOfEducationState.delete.done) {
      dispatch(getLevelOfEducations());
      dispatch(resetState({ type: 'delete' }));
    }
  }, [levelOfEducationState.delete.done, dispatch]);

  useEffect(() => {
    dispatch(getExams());
  }, [dispatch]);

  const allExams = examsState?.getExams?.result?.targetExams || [];

  const [deletingId, setDeletingId] = useState(null);

  const handleDelete = (id) => {
    setDeletingId(id);
    dispatch(deleteLevelOfEducation(id));
  };

  useEffect(() => {
    if (levelOfEducationState.delete.done) {
      setDeletingId(null);
    }
  }, [levelOfEducationState.delete.done]);

  const handleEdit = (education) => {
    setEditingEducation({
      ...education,
      targetExams: education.targetExams?.map(exam => exam.id) || []
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingEducation(null);
  };

  const handleSave = () => {
    dispatch(updateLevelOfEducation({ 
      id: editingEducation.id, 
      data: { 
        name: editingEducation.name,
        targetExams: editingEducation.targetExams || []
      }
    }));
  };

  const handleAddEducation = () => {
    dispatch(createLevelOfEducation({ 
      name: newEducation.name,
      targetExams: newEducation.targetExams || []
    }));
    setNewEducation({ name: '', targetExams: [] });
  };

  const data = levelOfEducationState.list.data?.result?.levelOfEducations || [];

  // Get all spinner states
  const isCreating = levelOfEducationState.create.spinner;
  const isUpdating = levelOfEducationState.update.spinner;
  const isDeleting = levelOfEducationState.delete.spinner;
  const isLoading = levelOfEducationState.list.spinner;

  return (
    <Container>
      <Header>
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}>
          <h4>Level of Educations</h4>
          <Button
            icon={<PlusIcon />}
            appearance="primary"
            onClick={() => setOpenAddModal(true)}
          >
            Add Education Level
          </Button>
        </div>
      </Header>

      <Content>
        <Table
          height={400}
          data={data}
          wordWrap="break-word"
          rowHeight={60}
          loading={isLoading}
        >
          <Column width={200} flexGrow={1}>
            <HeaderCell>Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>

          <Column width={150} flexGrow={1}>
            <HeaderCell>Created At</HeaderCell>
            <Cell dataKey="createdAt" />
          </Column>

          <Column width={150} flexGrow={1}>
            <HeaderCell>Updated At</HeaderCell>
            <Cell dataKey="updatedAt" />
          </Column>

          <Column width={250} flexGrow={1}>
            <HeaderCell>Target Exams</HeaderCell>
            <Cell>
              {rowData => (
                <div>
                  {rowData.targetExams?.map(exam => exam.name).join(', ') || '-'}
                </div>
              )}
            </Cell>
          </Column>

          <Column width={200} flexGrow={1} align="center">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <>
                  <Button
                    appearance="primary"
                    style={{ marginRight: 8 }}
                    color="red"
                    onClick={() => handleDelete(rowData.id)}
                    loading={isDeleting && deletingId === rowData.id}
                    disabled={isDeleting}
                  >
                    <i className="fa-solid fa-trash"></i>
                  </Button>
                  <Button
                    appearance="primary"
                    color="green"
                    onClick={() => handleEdit(rowData)}
                  >
                    <i className="fa-solid fa-pen-to-square"></i>
                  </Button>
                </>
              )}
            </Cell>
          </Column>
        </Table>

        {/* Edit Modals */}
        <Modal open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>Edit Education Level</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {editingEducation && (
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Name</Form.ControlLabel>
                  <Form.Control
                    name="name"
                    value={editingEducation.name}
                    onChange={(value) => setEditingEducation({ ...editingEducation, name: value })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Target Exams</Form.ControlLabel>
                  <div style={{ 
                    display: 'flex', 
                    flexWrap: 'wrap', 
                    gap: '10px',
                    maxHeight: '200px',
                    overflowY: 'auto'
                  }}>
                    <Form.Control
                      name="targetExams"
                      accepter={CheckboxGroup}
                      inline
                      value={editingEducation.targetExams || []}
                      onChange={(value) => {
                        setEditingEducation({ 
                          ...editingEducation, 
                          targetExams: value
                        });
                      }}
                    >
                      {allExams.map(exam => (
                        <Checkbox key={exam.id} value={exam.id}>
                          {exam.name}
                        </Checkbox>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button 
              onClick={handleSave} 
              appearance="primary"
              loading={isUpdating}
              disabled={isUpdating}
            >
              Save
            </Button>
            <Button 
              onClick={handleClose} 
              appearance="subtle"
              disabled={isUpdating}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Modal */}
        <Modal open={openAddModal} onClose={() => setOpenAddModal(false)}>
          <Modal.Header>
            <Modal.Title>Add New Education Level</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form fluid>
              <Form.Group>
                <Form.ControlLabel>Name</Form.ControlLabel>
                <Form.Control
                  name="name"
                  value={newEducation.name}
                  onChange={(value) => setNewEducation({ ...newEducation, name: value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Target Exams</Form.ControlLabel>
                <div style={{ 
                  display: 'flex', 
                  flexWrap: 'wrap', 
                  gap: '10px',
                  maxHeight: '200px',
                  overflowY: 'auto'
                }}>
                  <Form.Control
                    name="targetExams"
                    accepter={CheckboxGroup}
                    inline
                    value={newEducation.targetExams || []}
                    onChange={(value) => {
                      setNewEducation({ 
                        ...newEducation, 
                        targetExams: value
                      });
                    }}
                  >
                    {allExams.map(exam => (
                      <Checkbox key={exam.id} value={exam.id}>
                        {exam.name}
                      </Checkbox>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button 
              onClick={handleAddEducation} 
              appearance="primary"
              loading={isCreating}
              disabled={isCreating}
            >
              Add
            </Button>
            <Button 
              onClick={() => setOpenAddModal(false)} 
              appearance="subtle"
              disabled={isCreating}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Content>
    </Container>
  );
}

export default LevelOfEducations;
