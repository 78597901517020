import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'notificationSchedule';

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        data: null,
    };
}

const initialState = {
    list: createInitialState(),
    create: createInitialState(),
    update: createInitialState(),
    detail: createInitialState(),
    delete: createInitialState(),
};

// Create Notification Schedule
export const createNotificationSchedule = createAsyncThunk(
    'notificationSchedule/create',
    async (scheduleData, { rejectWithValue }) => {
        try {
            const response = await api.post('/super/api/notification/schedule/create', scheduleData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get Notification Schedules
export const getNotificationSchedules = createAsyncThunk(
    'notificationSchedule/getAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/super/api/notification/schedule/get');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Update Notification Schedule
export const updateNotificationSchedule = createAsyncThunk(
    'notificationSchedule/update',
    async (scheduleData, { rejectWithValue }) => {
        try {
            const response = await api.put('/super/api/notification/schedule/update', scheduleData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Delete Notification Schedule
export const deleteNotificationSchedule = createAsyncThunk(
    'notificationSchedule/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/super/api/notification/schedule/delete/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get Notification Schedule Detail
export const getNotificationScheduleDetail = createAsyncThunk(
    'notificationSchedule/getDetail',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/super/api/notification/schedule/detail/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const notificationScheduleSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetState: (state, action) => {
            const { type } = action.payload;
            state[type] = createInitialState();
        },
    },
    extraReducers: builder => {
        // List reducers
        builder
            .addCase(getNotificationSchedules.pending, state => {
                state.list.spinner = true;
                state.list.error = false;
            })
            .addCase(getNotificationSchedules.fulfilled, (state, action) => {
                state.list.done = true;
                state.list.spinner = false;
                state.list.error = false;
                state.list.data = action.payload;
            })
            .addCase(getNotificationSchedules.rejected, (state, action) => {
                state.list.done = false;
                state.list.spinner = false;
                state.list.error = action.payload;
            })

        // Create reducers
        builder
            .addCase(createNotificationSchedule.pending, state => {
                state.create.spinner = true;
                state.create.error = false;
            })
            .addCase(createNotificationSchedule.fulfilled, (state, action) => {
                state.create.done = true;
                state.create.spinner = false;
                state.create.error = false;
                state.create.data = action.payload;
            })
            .addCase(createNotificationSchedule.rejected, (state, action) => {
                state.create.done = false;
                state.create.spinner = false;
                state.create.error = action.payload;
            })

        // Update reducers
        builder
            .addCase(updateNotificationSchedule.pending, state => {
                state.update.spinner = true;
                state.update.error = false;
            })
            .addCase(updateNotificationSchedule.fulfilled, (state, action) => {
                state.update.done = true;
                state.update.spinner = false;
                state.update.error = false;
                state.update.data = action.payload;
            })
            .addCase(updateNotificationSchedule.rejected, (state, action) => {
                state.update.done = false;
                state.update.spinner = false;
                state.update.error = action.payload;
            })

        // Detail reducers
        builder
            .addCase(getNotificationScheduleDetail.pending, state => {
                state.detail.spinner = true;
                state.detail.error = false;
            })
            .addCase(getNotificationScheduleDetail.fulfilled, (state, action) => {
                state.detail.done = true;
                state.detail.spinner = false;
                state.detail.error = false;
                state.detail.data = action.payload;
            })
            .addCase(getNotificationScheduleDetail.rejected, (state, action) => {
                state.detail.done = false;
                state.detail.spinner = false;
                state.detail.error = action.payload;
            })

        // Delete reducers
        builder
            .addCase(deleteNotificationSchedule.pending, state => {
                state.delete.spinner = true;
                state.delete.error = false;
            })
            .addCase(deleteNotificationSchedule.fulfilled, (state, action) => {
                state.delete.done = true;
                state.delete.spinner = false;
                state.delete.error = false;
                state.delete.data = action.payload;
            })
            .addCase(deleteNotificationSchedule.rejected, (state, action) => {
                state.delete.done = false;
                state.delete.spinner = false;
                state.delete.error = action.payload;
            })
    },
});

export const { resetState } = notificationScheduleSlice.actions;
export const notificationScheduleReducer = notificationScheduleSlice.reducer;
