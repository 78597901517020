import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./i18n.js";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store/store.js";
import "rsuite/dist/rsuite.min.css";
import { CustomProvider } from "rsuite";

// Tema durumunu yönetmek için bir context oluşturuyoruz
export const ThemeContext = React.createContext({
  theme: 'dark',
  toggleTheme: () => {},
});

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = React.useState('dark');

  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'dark' ? 'light' : 'dark');
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <CustomProvider theme={theme}>
        {children}
      </CustomProvider>
    </ThemeContext.Provider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter basename="/">
    <ThemeProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </BrowserRouter>
);

reportWebVitals();
