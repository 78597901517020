import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "addUserPackage";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    addUserPackage: null,
  };
}

export const addUserPackage = createAsyncThunk(
  "addUserPackage",
  async (
    {
      userId,
      packageId,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.post("/super/api/user-package/create", {
        userId,
        packageId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const addUserPackageSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetAddUserPackage: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.addUserPackage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUserPackage.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(addUserPackage.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.addUserPackage = action.payload;
      })
      .addCase(addUserPackage.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetAddUserPackage } = addUserPackageSlice.actions;
export const addUserPackageReducer = addUserPackageSlice.reducer;
