import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'createCode';
const initialState = createInitialState();

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        createCode: null,
    };
}

export const createCode = createAsyncThunk('createCode', async (phoneNumber, { rejectWithValue }) => {
    try {
        const response = await api.post('/api/genesis', { phoneNumber });
        localStorage.setItem('phoneNumber', phoneNumber);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const createCodeSlice = createSlice({
    name: name,
    initialState,
    reducers: {
        resetCreateCode: state => {
            state.done = false;
            state.error = false;
            state.spinner = false;
            state.createCode = null;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(createCode.pending, state => {
                state.spinner = true;
                state.error = false;
            })
            .addCase(createCode.fulfilled, (state, action) => {
                state.done = true;
                state.spinner = false;
                state.error = false;
                state.createCode = action.payload;
            })
            .addCase(createCode.rejected, (state, action) => {
                state.done = false;
                state.spinner = false;
                state.error = action.payload;
            });
    },
});

export const { resetCreateCode } = createCodeSlice.actions;
export const createCodeReducer = createCodeSlice.reducer;
