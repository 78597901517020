import React from "react";
import SideNav from "./layout/SideNav";


function Index() {
  return (
  <div>
    <SideNav/>
  </div>    
  );
}

export default Index;