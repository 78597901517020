import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "getFeedbackTypes";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getFeedbackTypes: null,
  };
}

export const getFeedbackTypes = createAsyncThunk(
  "getFeedbackTypes",
  async (rejectWithValue) => {
    try {
      const response = await api.get(`/api/feedback/get/types`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

const getFeedbackTypesSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetGetFeedbackTypes: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getFeedbackTypes = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeedbackTypes.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getFeedbackTypes.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getFeedbackTypes = action.payload;
      })
      .addCase(getFeedbackTypes.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetGetFeedbackTypes } = getFeedbackTypesSlice.actions;
export const getFeedbackTypesReducer = getFeedbackTypesSlice.reducer;
