import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'levelOfEducation';

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        data: null,
    };
}

const initialState = {
    list: createInitialState(),
    create: createInitialState(),
    update: createInitialState(),
    delete: createInitialState(),
};

// Create Level of Education
export const createLevelOfEducation = createAsyncThunk(
    'levelOfEducation/create',
    async (educationData, { rejectWithValue }) => {
        try {
            const response = await api.post('/super/api/level-of-education/add', educationData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Update Level of Education
export const updateLevelOfEducation = createAsyncThunk(
    'levelOfEducation/update',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await api.put(`/super/api/level-of-education/update/${id}`, data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get All Level of Education
export const getLevelOfEducations = createAsyncThunk(
    'levelOfEducation/getAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/api/level-of-education/get');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Delete Level of Education
export const deleteLevelOfEducation = createAsyncThunk(
    'levelOfEducation/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/super/api/level-of-education/delete/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const levelOfEducationSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetState: (state, action) => {
            const { type } = action.payload;
            state[type] = createInitialState();
        },
    },
    extraReducers: builder => {
        // List reducers
        builder
            .addCase(getLevelOfEducations.pending, state => {
                state.list.spinner = true;
                state.list.error = false;
            })
            .addCase(getLevelOfEducations.fulfilled, (state, action) => {
                state.list.done = true;
                state.list.spinner = false;
                state.list.error = false;
                state.list.data = action.payload;
            })
            .addCase(getLevelOfEducations.rejected, (state, action) => {
                state.list.done = false;
                state.list.spinner = false;
                state.list.error = action.payload;
            })

        // Create reducers
        builder
            .addCase(createLevelOfEducation.pending, state => {
                state.create.spinner = true;
                state.create.error = false;
            })
            .addCase(createLevelOfEducation.fulfilled, (state, action) => {
                state.create.done = true;
                state.create.spinner = false;
                state.create.error = false;
                state.create.data = action.payload;
            })
            .addCase(createLevelOfEducation.rejected, (state, action) => {
                state.create.done = false;
                state.create.spinner = false;
                state.create.error = action.payload;
            })

        // Update reducers
        builder
            .addCase(updateLevelOfEducation.pending, state => {
                state.update.spinner = true;
                state.update.error = false;
            })
            .addCase(updateLevelOfEducation.fulfilled, (state, action) => {
                state.update.done = true;
                state.update.spinner = false;
                state.update.error = false;
                state.update.data = action.payload;
            })
            .addCase(updateLevelOfEducation.rejected, (state, action) => {
                state.update.done = false;
                state.update.spinner = false;
                state.update.error = action.payload;
            })

        // Delete reducers
        builder
            .addCase(deleteLevelOfEducation.pending, state => {
                state.delete.spinner = true;
                state.delete.error = false;
            })
            .addCase(deleteLevelOfEducation.fulfilled, (state, action) => {
                state.delete.done = true;
                state.delete.spinner = false;
                state.delete.error = false;
                state.delete.data = action.payload;
            })
            .addCase(deleteLevelOfEducation.rejected, (state, action) => {
                state.delete.done = false;
                state.delete.spinner = false;
                state.delete.error = action.payload;
            })
    },
});

export const { resetState } = levelOfEducationSlice.actions;
export const levelOfEducationReducer = levelOfEducationSlice.reducer;
