import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'notificationGroup';

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        data: null,
    };
}

const initialState = {
    list: createInitialState(),
    create: createInitialState(),
    update: createInitialState(),
    detail: createInitialState(),
    delete: createInitialState(),
};

// Create Notification Group
export const createNotificationGroup = createAsyncThunk(
    'notificationGroup/create',
    async ({ name, description, condition }, { rejectWithValue }) => {
        try {
            const response = await api.post('/super/api/notification/group/create', { name, description, condition });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get Notification Groups
export const getNotificationGroups = createAsyncThunk(
    'notificationGroup/getAll',
    async ({ pageSize, pageNumber }, { rejectWithValue }) => {
        try {
            const response = await api.get(`/super/api/notification/group/get?pageSize=${pageSize}&pageNumber=${pageNumber}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Update Notification Group
export const updateNotificationGroup = createAsyncThunk(
    'notificationGroup/update',
    async ({ id, name, description, condition }, { rejectWithValue }) => {
        try {
            const response = await api.put('/super/api/notification/group/update', { id, name, description, condition });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get Notification Group Detail
export const getNotificationGroupDetail = createAsyncThunk(
    'notificationGroup/getDetail',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/super/api/notification/group/detail/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Delete Notification Group
export const deleteNotificationGroup = createAsyncThunk(
    'notificationGroup/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/super/api/notification/group/delete/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const notificationGroupSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetState: (state, action) => {
            const { type } = action.payload;
            state[type] = createInitialState();
        },
    },
    extraReducers: builder => {
        // List reducers
        builder
            .addCase(getNotificationGroups.pending, state => {
                state.list.spinner = true;
                state.list.error = false;
            })
            .addCase(getNotificationGroups.fulfilled, (state, action) => {
                state.list.done = true;
                state.list.spinner = false;
                state.list.error = false;
                state.list.data = action.payload;
            })
            .addCase(getNotificationGroups.rejected, (state, action) => {
                state.list.done = false;
                state.list.spinner = false;
                state.list.error = action.payload;
            })

        // Create reducers
        builder
            .addCase(createNotificationGroup.pending, state => {
                state.create.spinner = true;
                state.create.error = false;
            })
            .addCase(createNotificationGroup.fulfilled, (state, action) => {
                state.create.done = true;
                state.create.spinner = false;
                state.create.error = false;
                state.create.data = action.payload;
            })
            .addCase(createNotificationGroup.rejected, (state, action) => {
                state.create.done = false;
                state.create.spinner = false;
                state.create.error = action.payload;
            })

        // Update reducers
        builder
            .addCase(updateNotificationGroup.pending, state => {
                state.update.spinner = true;
                state.update.error = false;
            })
            .addCase(updateNotificationGroup.fulfilled, (state, action) => {
                state.update.done = true;
                state.update.spinner = false;
                state.update.error = false;
                state.update.data = action.payload;
            })
            .addCase(updateNotificationGroup.rejected, (state, action) => {
                state.update.done = false;
                state.update.spinner = false;
                state.update.error = action.payload;
            })

        // Detail reducers
        builder
            .addCase(getNotificationGroupDetail.pending, state => {
                state.detail.spinner = true;
                state.detail.error = false;
            })
            .addCase(getNotificationGroupDetail.fulfilled, (state, action) => {
                state.detail.done = true;
                state.detail.spinner = false;
                state.detail.error = false;
                state.detail.data = action.payload;
            })
            .addCase(getNotificationGroupDetail.rejected, (state, action) => {
                state.detail.done = false;
                state.detail.spinner = false;
                state.detail.error = action.payload;
            })

        // Delete reducers
        builder
            .addCase(deleteNotificationGroup.pending, state => {
                state.delete.spinner = true;
                state.delete.error = false;
            })
            .addCase(deleteNotificationGroup.fulfilled, (state, action) => {
                state.delete.done = true;
                state.delete.spinner = false;
                state.delete.error = false;
                state.delete.data = action.payload;
            })
            .addCase(deleteNotificationGroup.rejected, (state, action) => {
                state.delete.done = false;
                state.delete.spinner = false;
                state.delete.error = action.payload;
            })
    },
});

export const { resetState } = notificationGroupSlice.actions;
export const notificationGroupReducer = notificationGroupSlice.reducer;
