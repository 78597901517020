import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import {
  Container,
  Content,
  Form,
  Button,
  Panel,
  Stack,
  VStack,
} from "rsuite";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { createCode, resetCreateCode } from "../../store/auth/createCode.slice";

function CreateCode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createCodeState = useSelector((state) => state.createCode);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (createCodeState.done) {
      dispatch(resetCreateCode());
      navigate("/verify-code");
      return;
    }

    
  }, [createCodeState.done, navigate]);

  useEffect(() => {
    if (createCodeState.error !== false) {
      const errorMessage = createCodeState?.error?.response?.data?.errorMessage;
      Swal.fire({
        title: "Hata!",
        text: errorMessage ? errorMessage : "Bir hata oluştu",
        icon: "error",
        confirmButtonText: "Tamam",
      });
    }
  }, [createCodeState.error]);

  function handleSubmit() {
    dispatch(createCode(phoneNumber));
  }

  return (
    <div className="full-page-center">
      <Container height={800}>
        <Content>
          <Stack
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
          >
            <Panel header="Flatun Admin" bordered style={{ width: 400 }}>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Phone Number</Form.ControlLabel>
                  <Form.Control
                  onChange={(value) => setPhoneNumber(value)}
                  name="name" />
                </Form.Group>
                <VStack spacing={10}>
                  <Button onClick={handleSubmit} appearance="primary" block>
                    Sign in
                  </Button>
                </VStack>
              </Form>
            </Panel>
          </Stack>
        </Content>
      </Container>
    </div>
  );
}

export default CreateCode;
