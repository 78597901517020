import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  createNotification,
  updateNotification,
  deleteNotification,
  getNotificationDetail,
  resetState,
} from "../../store/notification/notification.slice";
import {
  Table,
  IconButton,
  Container,
  Header,
  Content,
  Button,
  Modal,
  Form,
  Input,
  SelectPicker,
  DatePicker,
  Notification as RsuiteNotification,
  toaster,
  Pagination,
} from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import {
  getNotificationTemplates,
} from "../../store/notification/template.slice";
import {
  getNotificationGroups,
} from "../../store/notification/group.slice";
import {
  getNotificationSchedules,
} from "../../store/notification/schedule.slice";

const { Column, HeaderCell, Cell } = Table;

const statusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'Scheduled', value: 'scheduled' },
];

function Notifications() {
  const dispatch = useDispatch();
  const list = useSelector(state => state.notification.list);
  const create = useSelector(state => state.notification.create);
  const update = useSelector(state => state.notification.update);
  const detail = useSelector(state => state.notification.detail);
  const deleteState = useSelector(state => state.notification.delete);

  const [notificationModal, setNotificationModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentNotification, setCurrentNotification] = useState({
    id: "",
    templateID: "",
    groupID: "",
    scheduleID: "",
    status: "draft",
  });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [notificationToDelete, setNotificationToDelete] = useState(null);
  const [loadingNotificationId, setLoadingNotificationId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const totalItems = list.data?.result?.totalCount || 0; // API response'una göre düzeltildi

  // Add new selectors for templates, groups and schedules
  const templateList = useSelector(state => state.notificationTemplate.list);
  const groupList = useSelector(state => state.notificationGroup.list);
  const scheduleList = useSelector(state => state.notificationSchedule.list);

  // Format options for select pickers
  const templateOptions = React.useMemo(() => {
    if (!templateList.data?.result?.notifications) return [];
    return templateList.data.result.notifications.map(template => ({
      label: template.name,
      value: template.id
    }));
  }, [templateList.data]);

  const groupOptions = React.useMemo(() => {
    if (!groupList.data?.result?.data) return [];
    return groupList.data.result.data.map(group => ({
      label: group.name,
      value: group.id
    }));
  }, [groupList.data]);

  const scheduleOptions = React.useMemo(() => {
    if (!scheduleList.data?.result?.notificationSchedules) return [];
    return scheduleList.data.result.notificationSchedules.map(schedule => ({
      label: schedule.name,
      value: schedule.id
    }));
  }, [scheduleList.data]);

  useEffect(() => {
    dispatch(getNotifications({ pageSize, pageNumber: page }));
    dispatch(getNotificationTemplates());
    dispatch(getNotificationGroups({ pageSize: 100, pageNumber: 1 }));
    dispatch(getNotificationSchedules());
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    if (detail.done || detail.error) {
      setLoadingNotificationId(null);
      if (detail.done && detail.data) {
        const notification = detail.data.result.notification;
        setCurrentNotification({
          id: notification.id,
          templateID: notification.templateId,
          groupID: notification.groupId,
          scheduleID: notification.scheduleId,
          status: notification.status,
        });
        setNotificationModal(true);
      }
    }
  }, [detail.done, detail.error, detail.data]);

  // Success/Error handlers
  useEffect(() => {
    if (create.done || update.done) {
      toaster.push(
        <RsuiteNotification type="success" header="Success">
          Notification {currentNotification.id ? 'updated' : 'created'} successfully
        </RsuiteNotification>,
        { placement: 'topEnd' }
      );
      handleCloseModal();
      dispatch(getNotifications({ pageSize, pageNumber: page }));
      dispatch(resetState({ type: 'create' }));
      dispatch(resetState({ type: 'update' }));
    } else if (create.error || update.error) {
      toaster.push(
        <RsuiteNotification type="error" header="Error">
          Failed to {currentNotification.id ? 'update' : 'create'} notification
        </RsuiteNotification>,
        { placement: 'topEnd' }
      );
    }
  }, [create, update, currentNotification.id, dispatch, page, pageSize]);

  useEffect(() => {
    if (deleteState.done) {
      toaster.push(
        <RsuiteNotification type="success" header="Success">
          Notification deleted successfully
        </RsuiteNotification>,
        { placement: 'topEnd' }
      );
      
      // Eğer sayfadaki son item silindiyse ve birinci sayfa değilse
      // bir önceki sayfaya dön
      const currentPageItems = list.data?.result?.notifications?.length || 0;
      if (currentPageItems === 1 && page > 1) {
        setPage(prev => prev - 1);
      } else {
        // Aynı sayfayı yeniden yükle
        dispatch(getNotifications({ pageSize, pageNumber: page }));
      }

      setDeleteModalOpen(false);
      dispatch(resetState({ type: 'delete' }));
    } else if (deleteState.error) {
      toaster.push(
        <RsuiteNotification type="error" header="Error">
          Failed to delete notification
        </RsuiteNotification>,
        { placement: 'topEnd' }
      );
      setDeleteModalOpen(false);
    }
  }, [deleteState, dispatch, list.data?.result?.notifications?.length, page, pageSize]);

  useEffect(() => {
    return () => {
      setNotificationModal(false);
      setEditMode(false);
      setCurrentNotification({
        id: "",
        templateID: "",
        groupID: "",
        scheduleID: "",
        status: "draft"
      });
    };
  }, []);

  const handleAddNotification = () => {
    setEditMode(true);
    setCurrentNotification({
      id: "",
      templateID: "",
      groupID: "",
      scheduleID: "",
      status: "draft"
    });
    setNotificationModal(true);
  };

  const handleCloseModal = () => {
    setNotificationModal(false);
    setEditMode(false);
    setCurrentNotification({
      id: "",
      templateID: "",
      groupID: "",
      scheduleID: "",
      status: "draft"
    });
  };

  const handleInputChange = (value, name) => {
    setCurrentNotification(prev => ({ ...prev, [name]: value }));
  };

  const handleViewNotification = (notificationId) => {
    setEditMode(false);
    setLoadingNotificationId(notificationId);
    dispatch(resetState({ type: 'detail' }));
    dispatch(getNotificationDetail(notificationId));
  };

  const handleEditNotification = () => {
    setEditMode(true);
  };

  const handleSubmitNotification = () => {
    const notificationData = {
      ...currentNotification
    };

    if (currentNotification.id) {
      dispatch(updateNotification(notificationData));
    } else {
      dispatch(createNotification(notificationData));
    }
  };

  const handleDeleteNotification = (notificationId) => {
    setNotificationToDelete(notificationId);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    if (notificationToDelete) {
      dispatch(deleteNotification(notificationToDelete));
      setNotificationToDelete(null);
    }
  };

  const cancelDelete = () => {
    setDeleteModalOpen(false);
    setNotificationToDelete(null);
  };

  return (
    <Container>
      <Header>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 20 }}>
          <h4>Notifications</h4>
          <IconButton icon={<PlusIcon />} appearance="primary" onClick={handleAddNotification}>
            Add Notification
          </IconButton>
        </div>
      </Header>

      <Content>
        <Table
          height={window.innerHeight - 150}
          data={list.data?.result?.notifications || []}
          loading={list.spinner}
          wordWrap="break-word"
          rowHeight={60}
        >
          <Column width={200}>
            <HeaderCell>Title</HeaderCell>
            <Cell dataKey="title" />
          </Column>
          <Column width={300} flexGrow={1}>
            <HeaderCell>Body</HeaderCell>
            <Cell dataKey="body" />
          </Column>
          <Column width={150}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>
          <Column width={100} fixed="right">
            <HeaderCell>Actions</HeaderCell>
            <Cell>
              {(rowData) => (
                <span style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <IconButton
                    icon={<EditIcon />}
                    size="sm"
                    appearance="primary"
                    loading={loadingNotificationId === rowData.id}
                    onClick={() => handleViewNotification(rowData.id)}
                  />
                  <IconButton
                    icon={<TrashIcon />}
                    size="sm"
                    color="red"
                    onClick={() => handleDeleteNotification(rowData.id)}
                  />
                </span>
              )}
            </Cell>
          </Column>
        </Table>
        
        {totalItems > pageSize && ( // Sadece birden fazla sayfa varsa göster
          <div style={{ padding: '20px 0', display: 'flex', justifyContent: 'center' }}>
            <Pagination
              total={totalItems}
              limit={pageSize}
              activePage={page}
              onChangePage={setPage}
            />
          </div>
        )}
      </Content>

      <Modal open={notificationModal} onClose={handleCloseModal} size="lg">
        <Modal.Header>
          <Modal.Title>
            {editMode ? (currentNotification.id ? 'Edit' : 'Create') : 'View'} Notification
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form fluid>
            <Form.Group>
              <Form.ControlLabel>Template</Form.ControlLabel>
              <SelectPicker
                data={templateOptions}
                value={currentNotification.templateID}
                onChange={(value) => handleInputChange(value, "templateID")}
                disabled={!editMode}
                loading={templateList.spinner}
                block
              />
            </Form.Group>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Form.Group style={{ flex: 1 }}>
                <Form.ControlLabel>Group</Form.ControlLabel>
                <SelectPicker
                  data={groupOptions}
                  value={currentNotification.groupID}
                  onChange={(value) => handleInputChange(value, "groupID")}
                  disabled={!editMode}
                  loading={groupList.spinner}
                  block
                />
              </Form.Group>
              <Form.Group style={{ flex: 1 }}>
                <Form.ControlLabel>Schedule</Form.ControlLabel>
                <SelectPicker
                  data={scheduleOptions}
                  value={currentNotification.scheduleID}
                  onChange={(value) => handleInputChange(value, "scheduleID")}
                  disabled={!editMode}
                  loading={scheduleList.spinner}
                  block
                />
              </Form.Group>
            </div>
            <div style={{ display: 'flex', gap: '20px' }}>
              <Form.Group style={{ flex: 1 }}>
                <Form.ControlLabel>Status</Form.ControlLabel>
                <SelectPicker
                  data={statusOptions}
                  value={currentNotification.status}
                  onChange={(value) => handleInputChange(value, "status")}
                  disabled={!editMode}
                  block
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {editMode ? (
            <Button
              onClick={handleSubmitNotification}
              appearance="primary"
              loading={create.spinner || update.spinner}
            >
              Save Changes
            </Button>
          ) : (
            <Button onClick={handleEditNotification} appearance="primary">
              Edit Notification
            </Button>
          )}
          <Button onClick={handleCloseModal} appearance="subtle">
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal open={deleteModalOpen} onClose={cancelDelete}>
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this notification? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmDelete} appearance="primary" color="red" loading={deleteState.spinner}>
            Delete
          </Button>
          <Button onClick={cancelDelete} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Notifications;
