import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/api';

const name = 'entity';

function createInitialState() {
    return {
        done: false,
        error: false,
        spinner: false,
        data: null,
    };
}

const initialState = {
    list: createInitialState(),
    create: createInitialState(),
    update: createInitialState(),
    detail: createInitialState(),
    delete: createInitialState(),
};

// Create Entity Mapping
export const createEntityMapping = createAsyncThunk(
    'entity/create',
    async (entityData, { rejectWithValue }) => {
        try {
            const response = await api.post('/super/api/entity-mapping/create', entityData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Update Entity Mapping
export const updateEntityMapping = createAsyncThunk(
    'entity/update',
    async (entityData, { rejectWithValue }) => {
        try {
            const response = await api.put('/super/api/entity-mapping/update', entityData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get All Entity Mappings
export const getEntityMappings = createAsyncThunk(
    'entity/getAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/super/api/entity-mapping/get-all');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Get Entity Mapping Detail
export const getEntityMappingDetail = createAsyncThunk(
    'entity/getDetail',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.post(`/super/api/entity-mapping/get/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

// Delete Entity Mapping
export const deleteEntityMapping = createAsyncThunk(
    'entity/delete',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/super/api/entity-mapping/delete/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'An error occurred');
        }
    }
);

const entitySlice = createSlice({
    name,
    initialState,
    reducers: {
        resetState: (state, action) => {
            const { type } = action.payload;
            state[type] = createInitialState();
        },
    },
    extraReducers: builder => {
        // List reducers
        builder
            .addCase(getEntityMappings.pending, state => {
                state.list.spinner = true;
                state.list.error = false;
            })
            .addCase(getEntityMappings.fulfilled, (state, action) => {
                state.list.done = true;
                state.list.spinner = false;
                state.list.error = false;
                state.list.data = action.payload;
            })
            .addCase(getEntityMappings.rejected, (state, action) => {
                state.list.done = false;
                state.list.spinner = false;
                state.list.error = action.payload;
            })

        // Create reducers
        builder
            .addCase(createEntityMapping.pending, state => {
                state.create.spinner = true;
                state.create.error = false;
            })
            .addCase(createEntityMapping.fulfilled, (state, action) => {
                state.create.done = true;
                state.create.spinner = false;
                state.create.error = false;
                state.create.data = action.payload;
            })
            .addCase(createEntityMapping.rejected, (state, action) => {
                state.create.done = false;
                state.create.spinner = false;
                state.create.error = action.payload;
            })

        // Update reducers
        builder
            .addCase(updateEntityMapping.pending, state => {
                state.update.spinner = true;
                state.update.error = false;
            })
            .addCase(updateEntityMapping.fulfilled, (state, action) => {
                state.update.done = true;
                state.update.spinner = false;
                state.update.error = false;
                state.update.data = action.payload;
            })
            .addCase(updateEntityMapping.rejected, (state, action) => {
                state.update.done = false;
                state.update.spinner = false;
                state.update.error = action.payload;
            })

        // Detail reducers
        builder
            .addCase(getEntityMappingDetail.pending, state => {
                state.detail.spinner = true;
                state.detail.error = false;
            })
            .addCase(getEntityMappingDetail.fulfilled, (state, action) => {
                state.detail.done = true;
                state.detail.spinner = false;
                state.detail.error = false;
                state.detail.data = action.payload;
            })
            .addCase(getEntityMappingDetail.rejected, (state, action) => {
                state.detail.done = false;
                state.detail.spinner = false;
                state.detail.error = action.payload;
            })

        // Delete reducers
        builder
            .addCase(deleteEntityMapping.pending, state => {
                state.delete.spinner = true;
                state.delete.error = false;
            })
            .addCase(deleteEntityMapping.fulfilled, (state, action) => {
                state.delete.done = true;
                state.delete.spinner = false;
                state.delete.error = false;
                state.delete.data = action.payload;
            })
            .addCase(deleteEntityMapping.rejected, (state, action) => {
                state.delete.done = false;
                state.delete.spinner = false;
                state.delete.error = action.payload;
            })
    },
});

export const { resetState } = entitySlice.actions;
export const entityReducer = entitySlice.reducer;
