import Index from "./pages/Index";
import NotFound from "./pages/NotFound";
import CreateCode from "./pages/auth/CreateCode";
import VerifyCode from "./pages/auth/VerifyCode";
import Exams from "./pages/exam/Exams";
import FeedbackSettings from "./pages/generalFeedback/FeedbackSettings";
import Feedbacks from "./pages/generalFeedback/Feedbacks";
import AuthLayout from "./pages/layout/AuthLayout";
import SideNav from "./pages/layout/SideNav";
import Lessons from "./pages/lesson/Lessons";
import Packages from "./pages/package/Packages";
import UserPackages from "./pages/package/UserPackages";
import Subjects from "./pages/subject/Subjects";
import Users from "./pages/user/Users";
import ApplicationInfos from "./pages/applicationInfo/ApplicationInfos";
import Notifications from "./pages/notification/Notifications";
import NotificationGroups from "./pages/notification/NotificationGroups";
import UserNotifications from "./pages/notification/UserNotifications";
import NotificationTemplates from "./pages/notification/NotificationTemplates";
import NotificationSchedules from "./pages/notification/NotificationSchedules";
import Entities from "./pages/entity/Entities";
import LevelOfEducations from "./pages/levelOfEducation/LevelOfEducations";
import Files from "./pages/file/Files";
const routes = [
  {
    path: "/",
    element: <Index />,
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: "login",
        element: <CreateCode />,
      },
      {
        path: "verify-code",
        element: <VerifyCode />,
      },
    ],
  },
  {
    element: <SideNav />,
    children: [
      { path: "exams", element: <Exams /> },
      { path: "lessons", element: <Lessons /> },
      { path: "subjects", element: <Subjects /> },
      { path: "feedbacks", element: <Feedbacks /> },
      { path: "feedback-settings", element: <FeedbackSettings /> },
      { path: "users", element: <Users /> },
      { path: "packages", element: <Packages /> },
      { path: "user-packages", element: <UserPackages /> },
      { path: "versions", element: <ApplicationInfos /> },
      { path: "notifications", element: <Notifications /> },
      { path: "notification-groups", element: <NotificationGroups /> },
      { path: "user-notifications", element: <UserNotifications /> },
      { path: "notification-templates", element: <NotificationTemplates /> },
      { path: "notification-schedules", element: <NotificationSchedules /> },
      { path: "entities", element: <Entities /> },
      { path: "level-of-education", element: <LevelOfEducations /> },
      { path: "files", element: <Files /> },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
