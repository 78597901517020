import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../services/api";

const name = "getPackageDetail";
const initialState = createInitialState();

function createInitialState() {
  return {
    done: false,
    error: false,
    spinner: false,
    getPackageDetail: null,
  };
}
export const getPackageDetail = createAsyncThunk(
  "getPackageDetail",
  async ({ id }, rejectWithValue) => {
    try {
      const response = await api.get(`/api/package/detail/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

const getPackageDetailSlice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetGetPackageDetail: (state) => {
      state.done = false;
      state.error = false;
      state.spinner = false;
      state.getPackageDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPackageDetail.pending, (state) => {
        state.spinner = true;
        state.error = false;
      })
      .addCase(getPackageDetail.fulfilled, (state, action) => {
        state.done = true;
        state.spinner = false;
        state.error = false;
        state.getPackageDetail = action.payload;
      })
      .addCase(getPackageDetail.rejected, (state, action) => {
        state.done = false;
        state.spinner = false;
        state.error = action.payload;
      });
  },
});

export const { resetGetPackageDetail } = getPackageDetailSlice.actions;
export const getPackageDetailReducer = getPackageDetailSlice.reducer;
