import React, { useEffect, useState } from "react";
import { getFeedbackTypes } from "../../store/general-feedback/getFeedbackTypes.slice";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbacksByTypeId } from "../../store/general-feedback/getFeedbacksByTypeId.slice";
import { getUser } from "../../store/user/getUser.slice";
import { Table, Button, Modal, List, Container, Header, Content, Tabs, Notification, toaster } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

function Feedbacks() {
  const dispatch = useDispatch();
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [activeTab, setActiveTab] = useState(null);

  const getFeedbackTypesState = useSelector((state) => state.getFeedbackTypes);
  const getFeedbacksByTypeIdState = useSelector((state) => state.getFeedbacksByTypeId);
  const getUserState = useSelector((state) => state.getUser);

  useEffect(() => {
    dispatch(getFeedbackTypes());
  }, [dispatch]);

  useEffect(() => {
    if (getFeedbackTypesState.done && getFeedbackTypesState.getFeedbackTypes.result.feedbackTypes.length > 0) {
      const types = getFeedbackTypesState.getFeedbackTypes.result.feedbackTypes;
      setFeedbackTypes(types);
      
      // Set the first tab as active and fetch its data
      const firstTypeId = types[0].id;
      setActiveTab(firstTypeId);
      dispatch(getFeedbacksByTypeId({ feedbackTypeId: firstTypeId }));
    }
  }, [getFeedbackTypesState, dispatch]);

  useEffect(() => {
    if (getFeedbacksByTypeIdState.done) {
      setFeedbacks(getFeedbacksByTypeIdState.getFeedbacksByTypeId.result.feedbacks);
    }
  }, [getFeedbacksByTypeIdState]);

  useEffect(() => {
    if (getUserState.done) {
      setSelectedUser(getUserState.getUser.result.user);
    } else if (getUserState.error) {
      toaster.push(
        <Notification type="error" header="Error">
          Failed to fetch user details: {getUserState.error}
        </Notification>,
        { placement: 'topEnd' }
      );
    }
  }, [getUserState]);

  const handleSelect = (feedbackTypeId) => {
    setActiveTab(feedbackTypeId);
    dispatch(getFeedbacksByTypeId({ feedbackTypeId }));
  };

  const handleOpenUserModal = (userId) => {
    setSelectedUserId(userId);
    setOpenUserModal(true);
    dispatch(getUser({ userId }));
  };

  const handleCloseUserModal = () => {
    setOpenUserModal(false);
    setSelectedUserId(null);
  };

  const renderEmpty = () => (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <p>No feedbacks found</p>
    </div>
  );

  return (
    <Container>
      <Header style={{ marginBottom: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h4>Feedbacks</h4>
      </Header>
      <Content>
        <Tabs activeKey={activeTab} onSelect={handleSelect}>
          {feedbackTypes.map((feedbackType) => (
            <Tabs.Tab key={feedbackType.id} eventKey={feedbackType.id} title={feedbackType.name} />
          ))}
        </Tabs>

        <Table
          height={400}
          data={feedbacks}
          loading={getFeedbacksByTypeIdState.spinner}
          wordWrap="break-word"
          rowHeight={60}
          renderEmpty={renderEmpty}
        >
          <Column width={200} flexGrow={1}>
            <HeaderCell>ID</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column width={200} flexGrow={1}>
            <HeaderCell>Title</HeaderCell>
            <Cell dataKey="feedbackOption.value" />
          </Column>
          <Column width={300} flexGrow={2}>
            <HeaderCell>Content</HeaderCell>
            <Cell dataKey="content" />
          </Column>
          <Column width={200} flexGrow={1}>
            <HeaderCell>User</HeaderCell>
            <Cell>
              {(rowData) => (
                <Button
                  appearance="link"
                  onClick={() => handleOpenUserModal(rowData.user.id)}
                >
                  {`${rowData.user.name} ${rowData.user.surname}`}
                </Button>
              )}
            </Cell>
          </Column>
          <Column width={200} flexGrow={1}>
            <HeaderCell>Created At</HeaderCell>
            <Cell>{(rowData) => new Date(rowData.createdAt).toLocaleString()}</Cell>
          </Column>
        </Table>

        <Modal open={openUserModal} onClose={handleCloseUserModal}>
          <Modal.Header>
            <Modal.Title>User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedUser && (
              <List hover>
                {[
                  { label: 'Name', value: selectedUser.name },
                  { label: 'Surname', value: selectedUser.surname },
                  { label: 'Email', value: selectedUser.email },
                  { label: 'Birth Date', value: selectedUser.birthDate },
                  { label: 'Phone', value: selectedUser.phoneNumber },
                  { label: 'Address', value: selectedUser.address },
                ].map((item, index) => (
                  <List.Item key={index} style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    padding: '12px 20px',
                    borderBottom: index !== 5 ? '1px solid #e5e5e5' : 'none'
                  }}>
                    <span style={{ fontWeight: 'bold', width: '140px', color: '#575757' }}>{item.label}:</span>
                    <span style={{ marginLeft: '20px', flex: 1 }}>{item.value || '-'}</span>
                  </List.Item>
                ))}
              </List>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseUserModal} appearance="primary">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Content>
    </Container>
  );
}

export default Feedbacks;
